
export default [
    {
        "id": -1,
        "shikenId": 0,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "question1": "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１は２である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "choice4": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 0,
        "shikenId": 0,
        "parentNo": 1,
        "no": 2,
        "answerType": 1,
        "question1": "次のうち、最も適切なものを選びなさい。",
        "question2": "１＋１＝",
        "choice1": "1",
        "choice2": "2",
        "choice3": "3",
        "choice4": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 1,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "税理士資格を有しないファイナンシャル・プランナーが顧客からの要望により確定申告書を作成した場合、それが無償であれば税理士法に抵触しない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 2,
        "shikenId": 1,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "労働者災害補償保険（労災保険）の保険料は、労使折半で負担する。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 3,
        "shikenId": 1,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "公的介護保険の第１号被保険者が介護保険を利用し、サービスを受けた場合の自己負担割合は、所得の多寡に関わらず２割である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 4,
        "shikenId": 1,
        "parentNo": 1,
        "no": 4,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "確定拠出年金（個人型）の老齢給付金を一時金で受け取った場合、退職所得として所得税の課税対象となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 5,
        "shikenId": 1,
        "parentNo": 1,
        "no": 5,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "国民年金の付加保険料納付期間を有する者が、老齢基礎年金の繰上げ支給の申出をした場合、付加年金は、老齢基礎年金と同様の減額率によって減額される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 6,
        "shikenId": 1,
        "parentNo": 1,
        "no": 6,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "個人年金保険の契約を銀行の窓口で行った場合、生命保険契約者保護機構の保護の対象とならない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 7,
        "shikenId": 1,
        "parentNo": 1,
        "no": 7,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "延長（定期）保険とは保険料の払込みを中止し、その時点での解約返戻金を一時払い保険料として充当することで死亡保障額が元の契約と同じ定期保険に変更する方法である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 8,
        "shikenId": 1,
        "parentNo": 1,
        "no": 8,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "逓減定期保険は、保険期間が経過するほど保険金額および保険料が減少する。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 9,
        "shikenId": 1,
        "parentNo": 1,
        "no": 9,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "国内旅行傷害保険では、国内旅行中に発生した地震や津波によるケガも補償の対象としている。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 10,
        "shikenId": 1,
        "parentNo": 1,
        "no": 10,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "生命保険の保険料は、純保険料と付加保険料で構成されているが、純保険料は予定死亡率を用いて計算され、付加保険料は予定利率や予定事業費率を用いて計算される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 11,
        "shikenId": 1,
        "parentNo": 1,
        "no": 11,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "日経平均株価は、東京証券取引所プライム市場に上場する銘柄のうちから、市場流動性の高い225銘柄を選定し、その株価を使って算出する指数である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 12,
        "shikenId": 1,
        "parentNo": 1,
        "no": 12,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "国内総生産（GDP）は、一定期間内に国内で生産された財やサービスの付加価値の総額をあらわすため、海外で生産された付加価値は含まない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 13,
        "shikenId": 1,
        "parentNo": 1,
        "no": 13,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "市場金利の変動に伴う債券価格の変動幅は、他の条件が同じであれば、短期利付債券よりも長期利付債券の方が大きくなる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 14,
        "shikenId": 1,
        "parentNo": 1,
        "no": 14,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "証券取引所を通じて株式を売買する場合、指値注文よりも成行注文が優先される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 15,
        "shikenId": 1,
        "parentNo": 1,
        "no": 15,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "2024年１月１日より開始した新しいNISA制度の成長投資枠における、年間非課税投資額の限度は240万円である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 16,
        "shikenId": 1,
        "parentNo": 1,
        "no": 16,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "税金を負担する者と税金を納める者が違う税金を間接税といい、代表的なものに消費税や所得税などがある。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 17,
        "shikenId": 1,
        "parentNo": 1,
        "no": 17,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "減価償却方法について税務署長に届出をしなかった場合、所得税における法定償却方法は、定率法となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 18,
        "shikenId": 1,
        "parentNo": 1,
        "no": 18,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "納税者の配偶者が青色事業専従者や事業専従者である場合は、配偶者控除の適用を受けることができない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 19,
        "shikenId": 1,
        "parentNo": 1,
        "no": 19,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "不動産の貸付を事業的規模（貸家でいうと５棟以上、アパートやマンションでいうと、10室以上）で行っている場合、事業所得となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 20,
        "shikenId": 1,
        "parentNo": 1,
        "no": 20,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "不動産の登記事項証明書の交付請求は、インターネットを利用してオンラインで行うことができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 21,
        "shikenId": 1,
        "parentNo": 1,
        "no": 21,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "登記に所有者と記載されている者が真の所有者と異なる場合、売買契約を交わした買主は必ずしも法的な保護を受けられない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 22,
        "shikenId": 1,
        "parentNo": 1,
        "no": 22,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "不動産の売買契約を解除する場合、相手方が契約の履行に着手した後であっても、買主は手付金の放棄、売主はその倍額を現実に提供することにより契約を解除することができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 23,
        "shikenId": 1,
        "parentNo": 1,
        "no": 23,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "市街化調整区域において開発を行う場合、その規模が1,000㎡未満であれば都道府県知事の許可を受ける必要はない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 24,
        "shikenId": 1,
        "parentNo": 1,
        "no": 24,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "マンションの所有者が当該建物の賃貸を自ら業として行う場合、宅地建物取引業の免許を取得する必要はない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 25,
        "shikenId": 1,
        "parentNo": 1,
        "no": 25,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "防火地域または準防火地域に耐火建築物を建築した場合、建蔽率だけではなく、容積率の制限についても緩和を受けることができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 26,
        "shikenId": 1,
        "parentNo": 1,
        "no": 26,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "書面によって締結した贈与契約の場合、すでに履行している部分だけではなく、まだ履行していない部分についても解除することができない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 27,
        "shikenId": 1,
        "parentNo": 1,
        "no": 27,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "父からの贈与について相続時精算課税制度の適用を受けている者が、母からの贈与について暦年課税を選択することは可能である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 28,
        "shikenId": 1,
        "parentNo": 1,
        "no": 28,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "配偶者が相続により取得した財産の価格が、１億6,000万円以下または配偶者の法定相続分以下の場合、配偶者に相続税は課税されない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 29,
        "shikenId": 1,
        "parentNo": 1,
        "no": 29,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "個人が法人からの贈与により財産を取得した場合、取得した財産は贈与税の課税対象となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 30,
        "shikenId": 1,
        "parentNo": 1,
        "no": 30,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "個人間での取引において、時価と比べて著しく低い対価での譲渡があった場合は、原則として、時価と譲渡対価の差額に贈与税が課税される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 31,
        "shikenId": 1,
        "parentNo": 2,
        "no": 31,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "利率2.0％で複利運用しながら毎年一定金額を積み立てて10年後に8,000,000円を準備したい。<br>この場合に最低限必要な毎年の積立金額を下記の〈資料〉を利用して算出すると（　）となる。<br><br>〈資料〉<br><img src='/img/31.png' width='550'>",
        "choice1": "1）730,640円",
        "choice2": "2）824,240円",
        "choice3": "3）898,300円",
        "answer": 1,
        "score": 1
    },
    {
        "id": 32,
        "shikenId": 1,
        "parentNo": 2,
        "no": 32,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "業務外での病気やケガの療養や治療のため、連続して（ ① ）仕事を休んでいる場合に健康保険から支給される傷病手当金の金額は、休業１日につき支給開始日以前の継続した12カ月間の標準報酬月額の平均を30日で除した金額の（ ② ）となる。",
        "choice1": "1）① ２日以上 ② ２分の１",
        "choice2": "2）① ３日以上 ② ３分の２",
        "choice3": "3）① 10日以上 ② ４分の３",
        "answer": 2,
        "score": 1
    },
    {
        "id": 33,
        "shikenId": 1,
        "parentNo": 2,
        "no": 33,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "任意継続被保険者になるための要件は、被保険者期間が継続して２ヵ月以上ある者が資格喪失日から（ ① ）以内に申請をすることである。<br>要件を満たせば最長で（ ② ）は、任意継続被保険者となることができる。",
        "choice1": "1）① 10日 ② １年間",
        "choice2": "2）① 20日 ② ２年間",
        "choice3": "3）① １カ月 ② ３年間",
        "answer": 2,
        "score": 1
    },
    {
        "id": 34,
        "shikenId": 1,
        "parentNo": 2,
        "no": 34,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "住宅ローンの返済方法のうち、元利均等返済は、毎月の返済額が（ ① ）、返済期間の経過とともに毎月の元金の返済額が（ ② ）返済方法である。",
        "choice1": "1）① 減少し ② 増加する",
        "choice2": "2）① 一定で ② 減少する",
        "choice3": "3）① 一定で ② 増加する",
        "answer": 3,
        "score": 1
    },
    {
        "id": 35,
        "shikenId": 1,
        "parentNo": 2,
        "no": 35,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "遺族厚生年金の受給額は、老齢厚生年金の報酬比例部分を計算した額の（ ① ）に相当する額となる。また、短期要件の場合で被保険者月数が（ ② ）に満たない場合は、（ ② ）として計算する。",
        "choice1": "1）① ３分の２ ② 240月",
        "choice2": "2）① ４分の３ ② 300月",
        "choice3": "3）① ５分の４ ② 360月",
        "answer": 2,
        "score": 1
    },
    {
        "id": 36,
        "shikenId": 1,
        "parentNo": 2,
        "no": 36,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "定期保険特約付終身保険（更新型）の契約内容を変更せずに自動更新すると、更新後の保険料は更新前と比較すると（　）。",
        "choice1": "1）変わらない",
        "choice2": "2）高くなる",
        "choice3": "3）安くなる",
        "answer": 2,
        "score": 1
    },
    {
        "id": 37,
        "shikenId": 1,
        "parentNo": 2,
        "no": 37,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "変額個人年金保険は、（ ① ）の運用実績に基づいて将来受け取る年金額等が変動するが、一般に、（ ② ）については最低保証がある。",
        "choice1": "1）① 特別勘定 ② 死亡給付金額",
        "choice2": "2）① 特別勘定 ② 解約返戻金額",
        "choice3": "3）① 一般勘定 ② 解約返戻金額",
        "answer": 1,
        "score": 1
    },
    {
        "id": 38,
        "shikenId": 1,
        "parentNo": 2,
        "no": 38,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "自賠責保険から支払われる保険金の支払限度額（被害者１名につき）は、被害者が死亡した場合は（ ① ）、後遺障害の場合は障害の程度に応じて最高（ ② ）となる。",
        "choice1": "1）① 1,000万円 ② 2,500万円",
        "choice2": "2）① 3,000万円 ② 4,000万円",
        "choice3": "3）① 4,000万円 ② 3,000万円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 39,
        "shikenId": 1,
        "parentNo": 2,
        "no": 39,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "個人賠償責任保険において、（　）などの場合は補償の対象とはならない。",
        "choice1": "1）子供がボール遊びをしていて、他人の家の窓ガラスを割ってしまった",
        "choice2": "2）自転車で走行中に歩行者に衝突し、ケガをさせてしまった",
        "choice3": "3）友人から借りていたカメラを落として壊してしまった",
        "answer": 3,
        "score": 1
    },
    {
        "id": 40,
        "shikenId": 1,
        "parentNo": 2,
        "no": 40,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "生命保険会社が破綻した場合、原則として、破綻した時点における（ ① ）の（ ② ）までの金額が生命保険保護機構により補償される。ただし、高予定利率契約はこの限りではない。",
        "choice1": "1）① 保険金額 ② 50％",
        "choice2": "2）① 解約返戻金 ② 80％",
        "choice3": "3）① 責任準備金 ② 90％",
        "answer": 3,
        "score": 1
    },
    {
        "id": 41,
        "shikenId": 1,
        "parentNo": 2,
        "no": 41,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "クーポン2.0％、残存期間４年の固定利付債券を額面100円あたり106円で購入した場合の単利最終利回りは（　）となる。なお、答は表示単位の小数点以下第３位を四捨五入している。",
        "choice1": "1）0.23％",
        "choice2": "2）0.47％",
        "choice3": "3）0.62％",
        "answer": 2,
        "score": 1
    },
    {
        "id": 42,
        "shikenId": 1,
        "parentNo": 2,
        "no": 42,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "２つの資産で構成されているポートフォリオにおいて、相関係数が（ ① ）である場合、２つの資産が（ ② ）値動きをするため、リスク低減効果は最大となる。",
        "choice1": "1）① −１ ② 反対の",
        "choice2": "2）① ０ ② 反対の",
        "choice3": "3）① １ ② 同じ",
        "answer": 1,
        "score": 1
    },
    {
        "id": 43,
        "shikenId": 1,
        "parentNo": 2,
        "no": 43,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "日本国内に本店があり、預金保険制度の対象となる金融機関に預け入れた預金であっても（　）は預金保険制度の保護の対象とならない。",
        "choice1": "1）普通預金",
        "choice2": "2）定期預金",
        "choice3": "3）外貨預金",
        "answer": 3,
        "score": 1
    },
    {
        "id": 44,
        "shikenId": 1,
        "parentNo": 2,
        "no": 44,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "追加型株式投資信託を基準価格10,000円で購入し、最初の決算時に500円の収益分配金が支払われた。収益分配金を支払った後の基準価格が9,700円の場合、受け取った収益分配金のうち（ ① ）が普通分配金として課税され、（ ② ）が元本払戻金として非課税となる。",
        "choice1": "1）① 500円 ② 0円",
        "choice2": "2）① 200円 ② 300円",
        "choice3": "3）① 300円 ② 200円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 45,
        "shikenId": 1,
        "parentNo": 2,
        "no": 45,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "1,000,000円を年利率３％（１年複利）で３年間運用した場合の元利合計金額は、手数料や税金等を考慮しない場合、（　）となる。",
        "choice1": "1）1,009,000円",
        "choice2": "2）1,060,900円",
        "choice3": "3）1,092,727円",
        "answer": 3,
        "score": 1
    },
    {
        "id": 46,
        "shikenId": 1,
        "parentNo": 2,
        "no": 46,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "退職所得の計算において退職所得控除額は、勤続年数が20年までについては１年につき（ ① ）で計算し、20年を超える期間については１年につき（ ② ）で計算したものを合算する。また、勤続年数が１年未満の期間は切り上げる。",
        "choice1": "1）① 30万円 ② 60万円",
        "choice2": "2）① 40万円 ② 70万円",
        "choice3": "3）① 70万円 ② 100万円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 47,
        "shikenId": 1,
        "parentNo": 2,
        "no": 47,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "Ａさんの2024年分の各種所得の金額が下記の場合、総所得金額は（　）となる。<br><br>〈Aさんの2024年分の各種所得の金額〉<br><img src='/img/47.png' width='340'>",
        "choice1": "1）270万円",
        "choice2": "2）300万円",
        "choice3": "3）470万円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 48,
        "shikenId": 1,
        "parentNo": 2,
        "no": 48,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "生命保険料控除の計算において、2012年１月１日以降に契約した生命保険契約に係る介護医療保険料控除は、最高で（ ① ）となり、一般の生命保険料控除と個人年金保険料控除とを合わせた限度額は最高で（ ② ）となる。",
        "choice1": "1）① ３万円 ② ５万円",
        "choice2": "2）① ４万円 ② 12万円",
        "choice3": "3）① ５万円 ② 10万円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 49,
        "shikenId": 1,
        "parentNo": 2,
        "no": 49,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "住宅借入金等特別控除の適用を受けるための要件として、住宅ローンの返済期間は（ ① ）以上でなければならない。また、この住宅が店舗併用住宅の場合は、居住の用に供する部分が（ ② ）以上でなければならない。",
        "choice1": "1）① 10年 ② ２分の１",
        "choice2": "2）① 10年 ② ３分の２",
        "choice3": "3）① 15年 ② ３分の１",
        "answer": 1,
        "score": 1
    },
    {
        "id": 50,
        "shikenId": 1,
        "parentNo": 2,
        "no": 50,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "居住者が国内で支払いを受ける普通預金の利子は、復興特別所得税を含む所得税と住民税を合わせて（　）の税率により源泉徴収される。",
        "choice1": "1）10.21％",
        "choice2": "2）15.315％",
        "choice3": "3）20.315％",
        "answer": 3,
        "score": 1
    },
    {
        "id": 51,
        "shikenId": 1,
        "parentNo": 2,
        "no": 51,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "土地や建物等の譲渡に係る譲渡所得を計算する場合、譲渡した年の１月１日における所有期間が（　）を超えるものは、長期譲渡所得に区分される。",
        "choice1": "1）３年",
        "choice2": "2）５年",
        "choice3": "3）10年",
        "answer": 2,
        "score": 1
    },
    {
        "id": 52,
        "shikenId": 1,
        "parentNo": 2,
        "no": 52,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "居住用財産を譲渡した場合の税金について、「長期譲渡所得における軽減税率の特例」の適用を受けた場合、長期譲渡所得金額のうち、6,000万円までの部分には、（ ① ）の所得税率が適用され、6,000万円を超える部分には（ ② ）の所得税率が適用される。なお、復興特別所得税は考慮しないものとする。",
        "choice1": "1）① ５％ ② 10％",
        "choice2": "2）① 10％ ② 15％",
        "choice3": "3）① 15％ ② 20％",
        "answer": 2,
        "score": 1
    },
    {
        "id": 53,
        "shikenId": 1,
        "parentNo": 2,
        "no": 53,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "投資総額（自己資金＋借入金）が１億円の賃貸用不動産を購入し、年間収入が2,500万円、年間の費用が2,000万円であった場合、この賃貸用不動産の実質利回り（NOI利回り）はいくらになるか。",
        "choice1": "1）2％",
        "choice2": "2）4％",
        "choice3": "3）5％",
        "answer": 3,
        "score": 1
    },
    {
        "id": 54,
        "shikenId": 1,
        "parentNo": 2,
        "no": 54,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "宅地建物取引業法によると宅地建物取引業者が依頼人と専任媒介契約を締結した場合、有効期間は（　）となる。",
        "choice1": "1）１カ月",
        "choice2": "2）３カ月",
        "choice3": "3）６カ月",
        "answer": 2,
        "score": 1
    },
    {
        "id": 55,
        "shikenId": 1,
        "parentNo": 2,
        "no": 55,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "区分所有建物（マンションなど）に係る登記記録に記載されている面積は壁の内側の線で囲まれた（　）によって算出される。",
        "choice1": "1）水平面積",
        "choice2": "2）内法面積",
        "choice3": "3）壁芯面積",
        "answer": 2,
        "score": 1
    },
    {
        "id": 56,
        "shikenId": 1,
        "parentNo": 2,
        "no": 56,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "下記の親族関係図において、民法における父Ｄさんの法定相続分は（ ① ）となり、妻Ｂさんの法定相続分は（ ② ）となる。<br><br><img src='/img/56.png' width='570'>",
        "choice1": "1）① ３分の１ ② ３分の２",
        "choice2": "2）① ４分の１ ② ４分の３",
        "choice3": "3）① ２分の１ ② ２分の１",
        "answer": 1,
        "score": 1
    },
    {
        "id": 57,
        "shikenId": 1,
        "parentNo": 2,
        "no": 57,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "2024年３月15日に死亡したＡさんが所有していた上場株式Ｘの１株当たりの相続税評価額は、下記の〈資料〉によると（　）となる。<br><br>＜資料＞上場株式Xの価格<br><img src='/img/57.png' width='400'>",
        "choice1": "1）417円",
        "choice2": "2）420円",
        "choice3": "3）400円",
        "answer": 3,
        "score": 1
    },
    {
        "id": 58,
        "shikenId": 1,
        "parentNo": 2,
        "no": 58,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "相続において相続人が取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」の対象となる特定事業用宅地等であった場合、（ ① ）を限度面積として評価額の（ ② ）を減額することができる。",
        "choice1": "1）① 200㎡ ② 50％",
        "choice2": "2）① 330㎡ ② 80％",
        "choice3": "3）① 400㎡ ② 80％",
        "answer": 3,
        "score": 1
    },
    {
        "id": 59,
        "shikenId": 1,
        "parentNo": 2,
        "no": 59,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "準確定申告の期限は、相続があったことを知った日の翌日から（　）以内となっている。",
        "choice1": "1）３カ月",
        "choice2": "2）４カ月",
        "choice3": "3）10カ月",
        "answer": 2,
        "score": 1
    },
    {
        "id": 60,
        "shikenId": 1,
        "parentNo": 2,
        "no": 60,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "被相続人の（　）が相続により財産を取得した場合、その者は相続税額の２割加算の対象となる。",
        "choice1": "1）兄弟姉妹",
        "choice2": "2）父母",
        "choice3": "3）孫（子の代襲相続人）",
        "answer": 1,
        "score": 1
    },
    {
        "id": 61,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんは、老齢基礎年金の繰上げ支給の請求を検討している。60歳から老齢基礎年金の支給繰上げを請求する場合、受給できる金額の計算式として最も適切なものはどれか。なお年金額は2024年度価格に基づくものとする。",
        "choice1": "816,000円× 444/480 ×76％",
        "choice2": "816,000円× 444/480",
        "choice3": "816,000円× 444/480 ×58％",
        "answer": 1,
        "score": 4,
        "image": "2-1.png"
    },
    {
        "id": 62,
        "shikenId": 1,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "Ｘ社退職後におけるＡさん夫妻の公的年金制度の取扱いについて、ファイナンシャル・プランナーが説明した次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんは、国民年金の第１号被保険者へ種別変更の届出を行う必要があります。また、妻Ｂさんは国民年金の第３号被保険者の資格を喪失するので、第１号被保険者の手続きをする必要があります」",
        "choice2": "「Ａさんは、国民年金の保険料を納付することになります。国民年金の保険料の納付方法には、納付書による現金納付や口座振替、クレジットカードによる納付があります」",
        "choice3": "「Ａさんは、国民年金の保険料を納付することになります。国民年金の保険料は、最大５年間の保険料を前納することができ、前納期間や納付方法に応じて保険料の割引があります」",
        "answer": 3,
        "score": 3,
        "image": "2-1.png"
    },
    {
        "id": 63,
        "shikenId": 1,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "公的介護保険（以下、「介護保険」という）の保険給付について、ファイナンシャル・プランナーが説明した次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「介護保険の保険者（運営主体）は市区町村であるため、介護保険の保険給付を受けるためには、市町村（特別区を含む）から要介護認定または要支援認定を受ける必要があります」",
        "choice2": "「介護保険の第２号被保険者であるＡさんが、介護サービスの提供を受けた場合、原則として、実際にかかった費用の２割または３割を自己負担する必要があります」",
        "choice3": "「40歳以上65歳未満の第２号被保険者は、要介護状態または要支援状態となった原因が、初老期における認知症脳血管疾患などの加齢に伴う特定疾病である場合に限り、介護給付または予防給付を受けることができます」",
        "answer": 2,
        "score": 3,
        "image": "2-1.png"
    },
    {
        "id": 64,
        "shikenId": 1,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "新しいＮＩＳＡの「つみたて投資枠」の概要について、ファイナンシャル・プランナーがＡさんに対して説明した以下の文章の空欄①〜③に入る語句の組合せとして最も適切なものは、次のうちどれか。<br> <br> <div class='b-box'>新しいＮＩＳＡの「つみたて投資枠」は、株式投資信託や（ ① ）のうち一定の要件を満たすものが対象で、対象銘柄を指定したうえで、累積投資契約に基づく定期かつ継続的な買付けを行います。非課税投資枠は年間（ ② ）、非課税期間は（ ③ ）です。</div>",
        "choice1": "① ＥＴＦ ② 120万円 ③ 無期限",
        "choice2": "① Ｊ-ＲＥＩＴ ② 40万円 ③ 最長10年間",
        "choice3": "① ＥＴＦ ② 120万円 ③ 最長５年間",
        "answer": 1,
        "score": 3,
        "image": "2-2.png"
    },
    {
        "id": 65,
        "shikenId": 1,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "新しいＮＩＳＡを利用して購入した投資信託に係る課税関係について、ファイナンシャル・プランナーがＡさんに対して説明した次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「新しいＮＩＳＡを利用して購入した株式投資信託を解約した際に損失が生じた場合、その損失の金額は、特定口座で保有する上場株式等の譲渡益と損益通算することができません」",
        "choice2": "「新しいＮＩＳＡを利用して購入した株式投資信託の普通分配金は、非課税の対象とはならず、20.315％相当額が源泉徴収等されます」",
        "choice3": "「新しいＮＩＳＡを利用して購入した株式投資信託を、購入した年に解約した場合、当該譲渡益は非課税となります」",
        "answer": 2,
        "score": 3,
        "image": "2-2.png"
    },
    {
        "id": 66,
        "shikenId": 1,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "Ｙ社株式の各種投資指標に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「株価の相対的な割高・割安を判断する指標として、ＰＥＲ（株価収益率）があります。〈Ｙ社に関する資料〉から算出されるY株式のＰＥＲは16倍です」",
        "choice2": "「株価の相対的な割高・割安を判断する指標として、ＰＢＲ（株価純資産倍率）があります。〈Ｙ社に関する資料〉から算出されるY株式のＰＢＲは1.6倍です」",
        "choice3": "「株価に対する１株当たりの年間配当金の割合を示す指標を配当利回りといいます。〈Ｙ社に関する資料〉から算出されるY株式の配当利回りは４％です」",
        "answer": 3,
        "score": 4,
        "image": "2-2.png"
    },
    {
        "id": 67,
        "shikenId": 1,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年の所得税における医療費控除に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんが医療費控除の適用を受けるためには、確定申告をする必要があります」",
        "choice2": "「生命保険契約から支払われた入院給付金や健康保険から支給を受けた高額療養費がある場合、支払った医療費の総額からそれらの金額を控除します」",
        "choice3": "「Ａさんが2024年中に支払った医療費の金額の合計額が20万円を超えていない場合、医療費控除額は算出されません」",
        "answer": 3,
        "score": 3,
        "image": "2-3.png"
    },
    {
        "id": 68,
        "shikenId": 1,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年分の総所得金額は、次のうちどれか。なお、Ａさんは青色申告者ではないものとする。",
        "choice1": "680万円",
        "choice2": "620万円",
        "choice3": "586万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
        "answer": 3,
        "score": 4,
        "image": "2-3.png"
    },
    {
        "id": 69,
        "shikenId": 1,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>ⅰ）合計所得金額1,000万円以下のＡさんが適用を受けることができる配偶者控除の控除額は、（ ① ）である。<br> ⅱ）Ａさんが適用を受けることができる扶養控除の控除額は、（ ② ）である。<br> ⅲ）Ａさんが拠出した確定拠出年金の個人型年金の掛金は、その全額が（ ③ ）の対象となり、総所得金額から控除することができる。</div>",
        "choice1": "① 26万円 ② 38万円 ③ 社会保険料控除",
        "choice2": "① 38万円 ② 38万円 ③ 小規模企業共済等掛金控除",
        "choice3": "① 38万円 ② 63万円 ③ 社会保険料控除",
        "answer": 2,
        "score": 3,
        "image": "2-3.png"
    },
    {
        "id": 70,
        "shikenId": 1,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "① 400㎡ ② 2,560㎡",
        "choice2": "① 400㎡ ② 1,600㎡",
        "choice3": "① 320㎡ ② 1,280㎡",
        "answer": 2,
        "score": 4,
        "image": "2-4.png"
    },
    {
        "id": 71,
        "shikenId": 1,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "事業用定期借地権方式に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「事業用定期借地権方式とは、Ｇ社が甲土地を契約で一定期間賃借し、Ｇ社が建物を建設する手法です。土地を手放さずに安定した地代収入を得ることができ、契約期間満了時には土地が更地で返還されます」",
        "choice2": "「事業用定期借地権方式により、Ａさんが甲土地をＧ社に賃貸した後にＡさんの相続が開始した場合、相続税の課税価格の計算上、甲土地は貸家建付地として評価されますので、相続税額の軽減効果があります」",
        "choice3": "「事業用定期借地権の設定契約は、公正証書で作成しなければなりません」",
        "answer": 2,
        "score": 3,
        "image": "2-4.png"
    },
    {
        "id": 72,
        "shikenId": 1,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "不動産の価格に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "《設例》の図では相続税路線価が表示されているが、この道路に示された「200Ｄ」とは、１㎡当たりの価額が20万円、借地権割合が60％であることを示している。",
        "choice2": "土地の価格には、「実勢価格（取引価格）」 「公示価格」 「基準地標準価格」 「相続税路線価」 「固定資産税評価額」の５つがあるといわれ、このうち相続税路線価は公示価格の６割程度に設定されている。",
        "choice3": "公示価格は、毎年１月１日を基準日として、国土交通省の土地鑑定委員会が判定し、３月に公表されるものである。",
        "answer": 2,
        "score": 3,
        "image": "2-4.png"
    },
    {
        "id": 73,
        "shikenId": 1,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの相続に係る民法上の相続人およびその法定相続分の組み合わせとして最も適切なものは、次のうちどれか。",
        "choice1": "妻Ｂさん：２/３ 母Ｅさん：１/３",
        "choice2": "妻Ｂさん：１/２ 長男Ｃさん：１/４ 長女Ｄさん：１/４",
        "choice3": "妻Ｂさん：１/２ 長男Ｃさん：１/６ 長女Ｄさん：１/６ 母Ｅさん：１/６",
        "answer": 2,
        "score": 3,
        "image": "2-5.png"
    },
    {
        "id": 74,
        "shikenId": 1,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "小規模宅地等の評価減の特例（以下、「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "《設例》にある自宅の土地および家屋を妻Ｂさんが相続により取得し、その土地について特定居住用宅地等として本特例の適用を受けた場合、限度面積330㎡までの部分については、評価額を80％減額することができる。",
        "choice2": "妻Ｂさんが自宅の敷地と建物を相続し、本特例の適用を受けた場合、自宅の敷地（相続税評価額5,000万円）について、相続税の課税価格に算入すべき価額は4,000万円となる。",
        "choice3": "相続税の申告期限までに分割されていない宅地には適用されないが、申告期限から５年以内に分割された場合には適用される。",
        "answer": 1,
        "score": 4,
        "image": "2-5.png"
    },
    {
        "id": 75,
        "shikenId": 1,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの相続に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "配偶者に対する相続税額の軽減の適用を受けた場合、妻Ｂさんが相続により取得した財産の金額が、配偶者の法定相続分相当額と１億6,000万円とのいずれか多い金額までであれば、納付すべき相続税額は算出されない。",
        "choice2": "Ａさんが2024年分の所得税および復興特別所得税について確定申告書を提出しなければならない場合に該当するとき、相続人は、原則として、相続の開始があったことを知った日の翌日から10カ月以内に準確定申告書を提出しなければならない。",
        "choice3": "相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から２年以内に被相続人であるＡさんの死亡時の住所地を所轄する税務署長に提出しなければならない。",
        "answer": 1,
        "score": 3,
        "image": "2-5.png"
    },
    {
        "id": 76,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは、《設例》に基づいて、Ａさんが60歳に達するまで国民年金の保険料を納付した場合における老齢基礎年金の年金額を試算した。Ａさんが原則として、65歳から受給することができる老齢基礎年金の年金額を試算する計算式として、次のうち最も適切なものはどれか。なお、年金額は2024年度価額に基づいて計算するものとする。",
        "choice1": "<img src='/img/question/76_1.png' style='margin-top: -10px;margin-bottom:35px;height:40px'>",
        "choice2": "<img src='/img/question/76_2.png' style='margin-top: -31px;margin-bottom:35px;height:60px'>",
        "choice3": "<img src='/img/question/76_3.png' style='margin-top: -30px;margin-bottom:0px;height:60px'>",
        "answer": 1,
        "score": 4,
        "image": "3-1.png"
    },
    {
        "id": 77,
        "shikenId": 1,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは、公的介護保険（以下、「介護保険」という）について説明した。Ｍさんが、Ａさんに対して説明した次の記述のうち最も適切なものはどれか。",
        "choice1": "「介護保険の被保険者が保険給付を受けるためには、都道府県から要介護認定または要支援認定を受ける必要があります」",
        "choice2": "「介護保険の第２号被保険者であるＡさんは、原因を問わず要介護状態または要支援状態となった場合に保険給付を受けることができます」",
        "choice3": "「介護保険の第２号被保険者が保険給付を受けた場合、原則として実際にかかった費用（食費、居住費等を除く）の１割を自己負担する必要があります」",
        "answer": 3,
        "score": 3,
        "image": "3-1.png"
    },
    {
        "id": 78,
        "shikenId": 1,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは、Ｘ社退職後、老後の年金収入を増やすことができる方法や各種制度について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>ⅰ）「Ａさんは、国民年金基金に加入することができます。国民年金基金の掛金の上限は、原則として、個人型年金の掛金と合わせて月額（ ① ）円となります」<br> ⅱ）「Ａさんには学生納付特例期間がありますが、学生納付特例期間について追納できるのは（ ② ）年以内の保険料です」<br> ⅲ）「Ａさんは、国民年金の定額保険料に加えて付加保険料を納付することができます。仮に、Ａさんが付加保険料を180月納付し、65歳から老齢基礎年金を受け取る場合、老齢基礎年金の額に付加年金として年額（ ③ ）円が上乗せされます」</div>",
        "choice1": "① 72,000 ② 10 ③ 24,000",
        "choice2": "① 68,000 ② 10 ③ 36,000",
        "choice3": "① 68,000 ② 20 ③ 48,000",
        "answer": 2,
        "score": 3,
        "image": "3-1.png"
    },
    {
        "id": 79,
        "shikenId": 1,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "はじめに、Ｍさんは、Ａさんに対して、現在加入している生命保険の契約内容等について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんが現時点で病気を原因として亡くなった場合、死亡保険金3,500万円を受け取ることができます」",
        "choice2": "「遺族に必要な生活資金等の総額から遺族の収入金額等を差し引いた金額が、必要保障額として死亡保険金額を設定する目安となります。通常、必要保障額はお子様の成長とともに逓減するため、収入保障保険や逓減定期保険への加入も検討してはいかがでしょう」",
        "choice3": "「Ａさんが現時点において、脳卒中で所定の状態になったと医師に診断確定された場合、特定疾病保障定期保険特約から受け取る保険金の額は300万円となります。この保険金を受け取った後であっても、Ａさんが不慮の事故で180日以内に亡くなった場合に一時金として、受け取れる死亡保険金の額は4,500万円です」",
        "answer": 3,
        "score": 3,
        "image": "3-2.png"
    },
    {
        "id": 80,
        "shikenId": 1,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "次に、Ｍさんは、生命保険の見直しについて説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「現在加入している定期保険の契約変更を行うことで保険金額を減額させ、その分を必要性の高い介護保険などの保険料に充てることも検討事項の１つです」",
        "choice2": "「現在加入している生命保険を払済終身保険に変更した場合、死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
        "choice3": "「介護保障を検討する際には、公的介護保険等の社会保障制度と連動しているものか、保険金の支払事由が保険会社独自のものか、保険金額等どのような場合に保険金や給付金が支払われるか、加入前に確認しておきましょう」",
        "answer": 2,
        "score": 3,
        "image": "3-2.png"
    },
    {
        "id": 81,
        "shikenId": 1,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "最後に、Ｍさんは、全国健康保険協会管掌健康保険の高額療養費制度について説明した。Ｍさんが、Ａさんに対して説明した次の記述のうち最も適切なものはどれか。",
        "choice1": "「Ａさんに係る医療費の一部負担金の割合は、原則として１割となります」",
        "choice2": "「同一年内に、医療機関等に支払った医療費の一部負担金等の額が自己負担限度額を超えた場合、所定の手続により、自己負担限度額を超えた額が高額療養費として支給されます」",
        "choice3": "「一部負担金等の合計には、差額ベッド代、入院時の食事代、先進医療に係る費用等は含まれず、70歳未満の者の場合、原則として、医療機関ごとに、入院・外来、医科・歯科別に一部負担金等が21,000円以上のものが計算対象となります」",
        "answer": 3,
        "score": 4,
        "image": "3-2.png"
    },
    {
        "id": 82,
        "shikenId": 1,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "仮に、将来Ｘ社がＢさんに役員退職金7,000万円を支給した場合、Ｂさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ｂさんの役員在任期間（勤続年数）を40年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,400万円",
        "choice2": "2,200万円",
        "choice3": "2,400万円",
        "answer": 3,
        "score": 3,
        "image": "3-3.png"
    },
    {
        "id": 83,
        "shikenId": 1,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "Ｘ社が加入していた生命保険から死亡保険金を受け取った場合におけるＸ社の経理処理（仕訳）として、次のうち最も適切なものはどれか。なお、当該保険契約からの配当については考慮しないものとする。",
        "choice1": "<img src='/img/82_1.png' width='400'>",
        "choice2": "<img src='/img/82_2.png' width='400'>",
        "choice3": "<img src='/img/82_3.png' width='400'>",
        "answer": 1,
        "score": 4,
        "image": "3-3.png"
    },
    {
        "id": 84,
        "shikenId": 1,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは、長男Ｂさんに提案している終身保険の特徴について説明した。Ｍさんの長男Ｂさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「当該終身保険は、保険料払込期間における解約返戻金額を抑えることで、低解約返戻金型ではない終身保険と比較して保険料が割安となっています」",
        "choice2": "「Ｂさんが所定の高度障害状態になった場合、高度障害保険金6,000万円がＸ社に支払われます。さらに、その後Ｂさんが死亡した場合には、死亡保険金6,000万円がＸ社に支払われます」",
        "choice3": "「急な資金需要の発生により、Ｘ社が契約者貸付制度を利用した場合、借入金として経理処理します」",
        "answer": 2,
        "score": 3,
        "image": "3-3.png"
    },
    {
        "id": 85,
        "shikenId": 1,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "所得税の青色申告に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>・事業所得に係る取引を正規の簿記の原則に従い記帳し、その記帳に基づいて作成した貸借対照表、損益計算書その他の計算明細書を添付した確定申告書を法定申告期限内に提出することにより、事業所得の金額の計算上、青色申告特別控除として最高（ ① ）万円を控除することができます。<br> なお、従前の要件に加えて、e-Taxによる申告（電子申告）または電子帳簿保存を行うことで、（ ② ）万円の青色申告特別控除の適用を受けることができます。<br> ・青色申告者が適用を受けられる税務上の特典として、青色申告特別控除の適用、青色事業専従者給与の必要経費算入、翌年以後（ ③ ）年間の純損失の繰越控除、純損失の繰戻還付などがあります。</div>",
        "choice1": "① 10 ② 55 ③ ５",
        "choice2": "① 55 ② 65 ③ ３",
        "choice3": "① 55 ② 65 ③ ５",
        "answer": 2,
        "score": 4,
        "image": "3-4.png"
    },
    {
        "id": 86,
        "shikenId": 1,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <div class='b-box'>ⅰ）「Ａさんが解約した一時払個人変額年金保険（確定年金）解約返戻金は、税務上、（ ① ）として所得税の課税対象になります」<br> ⅱ）「妻Ｂさんが青色事業専従者として給与の支払いを受けている場合、Ａさんは、妻Ｂさんについて配偶者控除の適用を受けることが（ ② ）」<br> ⅲ）「Ａさんが適用を受けることができる長男Ｃさんに係る扶養控除の額は、（ ③ ）万円です」</div>",
        "choice1": "① 譲渡所得 ② できません ③ 38",
        "choice2": "① 雑所得 ② できます ③ 38",
        "choice3": "① 一時所得 ② できません ③ 63",
        "answer": 3,
        "score": 3,
        "image": "3-4.png"
    },
    {
        "id": 87,
        "shikenId": 1,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの2024年分の総所得金額は、次のうちどれか。",
        "choice1": "600万円",
        "choice2": "625万円",
        "choice3": "700万円",
        "answer": 2,
        "score": 3,
        "image": "3-4.png"
    },
    {
        "id": 88,
        "shikenId": 1,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの相続に関する以下の文章の空欄①〜③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>・Ａさんの相続における遺産に係る基礎控除額は、（ ① ）万円である。<br> ・相続税の申告書は、原則として、相続の開始があったことを知った日の翌日から（ ② ）以内に提出しなければならない。<br> ・長男Ｃさんが受け取った死亡保険金は、みなし相続財産として相続税の課税対象となるが、死亡保険金の非課税金額の規定の適用を受けることで、相続税の課税価格に算入される金額は（ ③ ）万円となる。</div>",
        "choice1": "① 4,800 ② 10カ月 ③ 2,500",
        "choice2": "① 4,800 ② 12カ月 ③ 2,500",
        "choice3": "① 8,000 ② 12カ月 ③ 2,000",
        "answer": 1,
        "score": 3,
        "image": "3-5.png"
    },
    {
        "id": 89,
        "shikenId": 1,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの相続に関する次の記述うち、最も不適切なものはどれか。",
        "choice1": "妻Ｂさんが自宅の敷地を相続により取得し、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、自宅の敷地（相続税評価額9,000万円）について、相続税の課税価格に算入すべき価額を1,800万円とすることができる。",
        "choice2": "自筆証書遺言は、所定の手続により、法務局（遺言書保管所）に保管することができる。法務局（遺言書保管所）に保管された自筆証書遺言は、相続開始時、家庭裁判所の検認が不要となる。",
        "choice3": "妻Ｂさんが「配偶者に対する相続税額の軽減」の適用を受けるためには、Ａさんの相続開始時において、Ａさんとの婚姻期間が10年以上でなければならない。",
        "answer": 3,
        "score": 4,
        "image": "3-5.png"
    },
    {
        "id": 90,
        "shikenId": 1,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの相続に係る課税遺産総額（ 「課税価格の合計額－遺産に係る基礎控除額」 ）を２億4,000万円と仮定した場合の相続税の総額は、次のうちどれか。",
        "choice1": "1,200万円",
        "choice2": "5,300万円",
        "choice3": "7,400万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
        "answer": 2,
        "score": 3,
        "image": "3-5.png"
    },
    {
        "id": 91,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 4,
        "question1": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を遵守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "投資助言・代理業の登録をしていないＦＰが、顧客からの質問を受け、ＮＩＳＡ（少額投資非課税制度）の仕組みと特徴について説明した。",
        "choice2": "税理士資格を有していないＦＰが、無料相談会において、相談者の持参した資料に基づき、相談者が納付すべき相続税の具体的な税額計算を行った。",
        "choice3": "弁護士資格を有しないＦＰが、遺産分割で争っている顧客の依頼を受け、法律相談に基づく和解交渉を行い報酬を得た。",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 92,
        "shikenId": 1,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 4,
        "question1": "下記は、会社員の田中さんの家庭のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表に関する次の記述のうち、最も適切なものはどれか。なお、計算に当たっては、キャッシュフロー表中に記載の整数を使用することとし、計算結果は万円未満を四捨五入することとする。<br><img src=\"/img/question/92_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "空欄（ア）に入る数値とその求め方：「520×(1＋0.01)<sup>2</sup>＝<u>530</u>」",
        "choice2": "空欄（イ）に入る数値とその求め方：「696－742＝<u>▲46</u>」",
        "choice3": "空欄（ウ）に入る数値とその求め方：「(520－102)×(1＋0.01)＝<u>422</u>」",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 93,
        "shikenId": 1,
        "parentNo": 1,
        "no": 3,
        "groupId": 2,
        "answerType": 4,
        "question1": "下表の空欄（ア）に当てはまる金額として、正しいものはどれか。なお、〈資料〉に記載のあるデータに基づいて解答するものとする。<br><br>〈バランスシート〉<br><img src=\"/img/question/103_2.png\" class=\"question-img\" /><br><br>〈資料〉<br><img src=\"/img/question/106_1.png\" class=\"question-img\" /><br><br>［負債残高］<br> 住宅ローン（自宅） ：3,500万円（団体信用生命保険付）<br> 自動車ローン（自家用）： 120万円<br> <br> 〈その他〉<br> 上記以外については、特に指定のない限り一切考慮しないこととする。 ",
        "question2": "",
        "choice1": "2,080（万円）",
        "choice2": "3,620（万円）",
        "choice3": "5,700（万円）",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 94,
        "shikenId": 1,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 4,
        "question1": "",
        "question2": "会社員の榊治夫さんは、今後15年間で毎年36万円ずつ積立貯蓄をして、老後の資金準備をしたいと考えている。積立期間中に年利2.0％で複利運用できるものとした場合、15年後の積立金額として、正しいものはどれか。<br> なお、下記〈資料〉の3つの係数の中から最も適切な係数を選択して計算し、解答に当たっては、万円未満を四捨五入することとする。また、税金や記載のない事項については一切考慮しないこととする。<br> <br><img src=\"/img/question/105_2.png\" class=\"question-img\" />",
        "choice1": "623万円",
        "choice2": "463万円",
        "choice3": "48万円",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 95,
        "shikenId": 1,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 4,
        "question1": "",
        "question2": "会社員の葛西真一さんは、会社の定期健康診断で異常を指摘され、2024年4月に2週間ほど入院をして治療を受けた。その際の病院への支払いが高額であったため、健康保険の高額療養費制度を利用した。真一さんの2024年4月の保険診療に係る総医療費が60万円であった場合、高額療養費制度により払戻しを受けることができる金額として、正しいものはどれか。なお、真一さんは全国健康保険協会管掌健康保険（協会けんぽ）の被保険者で、標準報酬月額は「35万円」である。また、2024年4月に支払った医療費はこの入院に係るもののみであり、今回の入院について健康保険限度額適用認定証は提示していないものとする。<br> <br><img src=\"/img/question/106_2.png\" class=\"question-img\" />",
        "choice1": "83,430円",
        "choice2": "96,570円",
        "choice3": "180,000円",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 96,
        "shikenId": 1,
        "parentNo": 1,
        "no": 6,
        "groupId": 3,
        "answerType": 4,
        "question1": "下記〈資料〉は、ＴＹ銀行の顧客配布用・投資信託ガイドの「投資信託に関するリスク」のページの一部（抜粋）である。〈資料〉に関する次の記述のうち、最も適切なものはどれか。<br><br>〈資料〉<br><img src=\"/img/question/93_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "空欄（ ア ）に入る語句は、「信用リスク」である。",
        "choice2": "空欄（ イ ）に入る語句は、「為替変動リスク」である。",
        "choice3": "空欄（ ウ ）に入る語句は、「流動性リスク」である。",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 97,
        "shikenId": 1,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 4,
        "question1": "個人向け国債に関する下記の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br>〈資料〉<br><img src=\"/img/question/94_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "（ア）1万円 （イ）0.03％ （ウ）2年",
        "choice2": "（ア）1万円 （イ）0.05％ （ウ）1年",
        "choice3": "（ア）5万円 （イ）0.05％ （ウ）2年",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 98,
        "shikenId": 1,
        "parentNo": 1,
        "no": 8,
        "groupId": 4,
        "answerType": 4,
        "question1": "下記〈資料〉に関する次の記述の空欄（ア）、（イ）にあてはまる数値または語句の組み合わせとして、正しいものはどれか。なお、空欄（ア）の解答に当たっては、小数点以下第3位を四捨五入すること。<br><br>〈資料〉<br><img src=\"/img/question/95_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "（ア）1.40 （イ）ＫＹ",
        "choice2": "（ア）1.60 （イ）ＫＸ",
        "choice3": "（ア）1.60 （イ）ＫＹ",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 99,
        "shikenId": 1,
        "parentNo": 1,
        "no": 9,
        "groupId": 4,
        "answerType": 4,
        "question1": "建築基準法に基づき、下記〈資料〉の土地に建築物を建築する場合、建築面積の最高限度（ア）と延べ面積（床面積の合計）の最高限度（イ）の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><br>〈資料〉<br><img src=\"/img/question/96_2.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "（ア）180㎡ （イ）480㎡",
        "choice2": "（ア）180㎡ （イ）600㎡",
        "choice3": "（ア）300㎡ （イ）600㎡",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 100,
        "shikenId": 1,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 4,
        "question1": "定期借地権に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "一般定期借地権の存続期間は50年以上であり、契約方法には制限がない。",
        "choice2": "事業用定期借地権の対象は、主として事業の用に供する建物（居住用建物を含む）であり、契約方法は公正証書でしなければならない。",
        "choice3": "建物譲渡特約付借地権の存続期間は30年以上であり、契約方法には制限がない。",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 101,
        "shikenId": 1,
        "parentNo": 1,
        "no": 11,
        "groupId": 5,
        "answerType": 4,
        "question1": "土地の登記記録において、権利部のうちの甲区（ア）、乙区（イ）に記録される事項の組み合わせとして、誤っているものはどれか。",
        "question2": "",
        "choice1": "（ア）抵当権設定登記（イ）所有権保存登記",
        "choice2": "（ア）所有権移転登記（イ）抵当権設定登記",
        "choice3": "（ア）所有権移転登記（イ）地上権設定登記",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 102,
        "shikenId": 1,
        "parentNo": 1,
        "no": 12,
        "groupId": 5,
        "answerType": 4,
        "question1": "下記の〈資料〉に基づき、石川雄一郎さんが加入している生命保険の保障内容に関する次の記述の空欄（ア）、（イ）にあてはまる金額の組合せとして、正しいものはどれか。なお、保険契約は有効に継続しているものとし、石川雄一郎さんはこれまでに下記の〈資料〉の保険から保険金及び給付金を一度も受け取っていないものとし、各々の記述は独立した問題であり、相互に影響を与えないものとする。<br><br><img src=\"/img/question/96_1.png\" class=\"question-img\" /><br><br>石川雄一郎さんが、2024年中に脳卒中によって死亡した場合、保険会社から死亡保険金（　ア　）が支払われる。また、年間（　イ　）の年金が５年間支払われる。",
        "question2": "",
        "choice1": "（ア）1,700万円 （イ）300万円",
        "choice2": "（ア）2,000万円 （イ）500万円",
        "choice3": "（ア）2,000万円 （イ）300万円",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 103,
        "shikenId": 1,
        "parentNo": 1,
        "no": 13,
        "groupId": 6,
        "answerType": 4,
        "question1": "下記の（ア）～（ウ）のイメージ図は、定期保険、終身保険、養老保険のいずれかを表したものである。下記のイメージ図に関する次の記述のうち、最も適切なものはどれか。<br><img src=\"/img/question/99_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "（ア）は「終身保険」に関するイメージ図である。",
        "choice2": "（イ）は「定期保険」に関するイメージ図である。",
        "choice3": "（ウ）は「養老保険」に関するイメージ図である。",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 104,
        "shikenId": 1,
        "parentNo": 1,
        "no": 14,
        "groupId": 6,
        "answerType": 4,
        "question1": "損害保険の種類と事故の内容に関する次の記述のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "国内旅行傷害保険は、国内旅行中に発生した地震や津波によるケガも対象とする。",
        "choice2": "普通傷害保険は、草野球の試合中にボールが右指を直撃し、被保険者が骨折した場合の怪我の補償を対象とする。",
        "choice3": "個人賠償責任保険（特約）は、被保険者が仕事で自転車を使用中に、誤って歩行者と接触し、ケガをさせた場合の損害賠償責任の補償を対象とする。",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 105,
        "shikenId": 1,
        "parentNo": 1,
        "no": 15,
        "groupId": 6,
        "answerType": 4,
        "question1": "Ｋ株式会社の会社員である竹市雄二さんが2024年中に支払った医療費等が下記〈資料〉のとおりである場合、竹市さんの2024年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、竹市さんの所得は給与所得600万円のみであり、妻は竹市さんと生計を一にしている。また、医療費控除の金額が最も大きくなるよう計算すること。<br><br><img src=\"/img/question/100_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "290,000円",
        "choice2": "390,000円",
        "choice3": "450,000円",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 106,
        "shikenId": 1,
        "parentNo": 1,
        "no": 16,
        "groupId": 7,
        "answerType": 4,
        "question1": "会社員の山川さんは、2024年中に勤務先を定年退職した。山川さんの退職に係るデータが下記〈資料〉のとおりである場合、山川さんの所得税に係る退職所得の金額として、正しいものはどれか。<br><br><img src=\"/img/question/101_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "1,280万円",
        "choice2": "1,220万円",
        "choice3": "640万円",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 107,
        "shikenId": 1,
        "parentNo": 1,
        "no": 17,
        "groupId": 7,
        "answerType": 4,
        "question1": "給与所得者である杉田さんは、２０２４年中に認定住宅を購入し、直ちに居住を開始した。杉田さんは、住宅借入金等特別控除（以下「住宅ローン控除」という）の適用を受けたいと考えており、ＦＰで税理士でもある村瀬さんに相談をした。村瀬さんの住宅ローン控除に関する次の説明のうち、最も適切なものはどれか。",
        "question2": "",
        "choice1": "「住宅ローン控除の適用対象となる住宅の床面積は、７０㎡以上とされています。」",
        "choice2": "「住宅ローン控除の控除額は、『住宅借入金等の年末残高等×１％』で計算されます。」",
        "choice3": "「住宅ローン控除は、その年分の合計所得金額が2,０００万円以下でなければ適用を受けることができません。」",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 108,
        "shikenId": 1,
        "parentNo": 1,
        "no": 18,
        "groupId": 7,
        "answerType": 4,
        "question1": "2024年4月10日に相続が開始された被相続人の〈親族関係図〉が下記のとおりである場合、民法上の相続人および法定相続分として、正しいものはどれか。なお、記載のない条件については、一切考慮しないこととする。<br><br><img src=\"/img/question/102_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "配偶者 1/2 長男 1/4 長女 1/4",
        "choice2": "配偶者 2/3 母 1/3",
        "choice3": "配偶者 1/2 長男 1/2",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 109,
        "shikenId": 1,
        "parentNo": 1,
        "no": 19,
        "groupId": 7,
        "answerType": 4,
        "question1": "村西誠一さん（30歳）が2024年中に贈与を受けた財産の価額と贈与者は以下のとおりである。誠一さんの2024年分の贈与税額として、正しいものはどれか。なお、2024年中において、誠一さんはこれ以外の財産の贈与を受けておらず、相続時精算課税制度は選択していないものとする。<br><br><img src=\"/img/question/103_1.png\" class=\"question-img\" /><br><br>〈贈与税の速算表〉<br>（イ）18歳以上の者が直系尊属から贈与を受けた財産の場合<br><img src=\"/img/question/104_1.png\" class=\"question-img\" /><br><br>（ロ）上記（イ）以外の場合<br><img src=\"/img/question/105_1.png\" class=\"question-img\" />",
        "question2": "",
        "choice1": "700万円",
        "choice2": "110万円",
        "choice3": "88万円",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 110,
        "shikenId": 1,
        "parentNo": 1,
        "no": 20,
        "groupId": 7,
        "answerType": 4,
        "question1": "長谷川信子さんは、夫から居住用不動産の贈与を受けた。信子さんは、この居住用不動産の贈与について、贈与税の配偶者控除の適用を受けることを検討しており、ＦＰで税理士でもある八代さんに相談をした。この相談に対する八代さんの回答について次の記述のうち、最も不適切なものはどれか。",
        "question2": "",
        "choice1": "「贈与税の配偶者控除の対象となる贈与は、居住用不動産の贈与または居住用不動産を取得するための金銭の贈与です。」",
        "choice2": "「贈与税の配偶者控除を受ける場合、基礎控除110万円とは別に最高2,500万円の控除を受けることができます。」",
        "choice3": "「贈与があった日において、配偶者との婚姻期間が20年以上あること等の所定の要件を満たす必要があります。」",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 201,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "司法書士資格を有しないファイナンシャル・プランナーが顧客の依頼により任意後見契約を締結し、任意後見受任者となることは司法書士法に抵触しない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 202,
        "shikenId": 2,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "確定拠出年金（個人型）の老齢給付金を一時金で受け取った場合、雑所得として所得税の課税対象となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 203,
        "shikenId": 2,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "雇用保険から基本手当が支給される場合、原則として、離職の日の翌日から１年間に受給しなければならない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 204,
        "shikenId": 2,
        "parentNo": 1,
        "no": 4,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "フラット35を利用する場合、年収に対する返済額の割合が定められており、要件を満たせば融資が受けられる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 205,
        "shikenId": 2,
        "parentNo": 1,
        "no": 5,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "60歳台後半の厚生年金被保険者に支給される老齢厚生年金は、総報酬月額相当額と基本月額の合計が40万円を超える場合、年金額の一部または全部が支給停止となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 206,
        "shikenId": 2,
        "parentNo": 1,
        "no": 6,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "収入保障保険から支払われる死亡保険金を一時金で受取る場合の金額は、年金形式で受取る金額よりも少なくなる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 207,
        "shikenId": 2,
        "parentNo": 1,
        "no": 7,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "終身保険では、保険料の払込期間が満了すると、保障の内容を老後の年金や介護に変更することができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 208,
        "shikenId": 2,
        "parentNo": 1,
        "no": 8,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "学資保険に出生前加入特則を付加することにより、子が出生する前から保険に加入することができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 209,
        "shikenId": 2,
        "parentNo": 1,
        "no": 9,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "リビング・ニーズ特約は、被保険者の余命が１年以内と判断された場合に、死亡保険金の一部または全部を受け取ることができる特約である。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 210,
        "shikenId": 2,
        "parentNo": 1,
        "no": 10,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "少額短期保険業者と締結した保険契約は、生命保険契約者保護機構および損害保険契約者保護機構による補償の対象とならない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 211,
        "shikenId": 2,
        "parentNo": 1,
        "no": 11,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "マネーストック統計とは、個人、一般法人、地方公共団体が保有する通貨量の残高を集計したものである。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 212,
        "shikenId": 2,
        "parentNo": 1,
        "no": 12,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "外貨建金融商品を円で購入する際の為替手数料は、取扱金融機関によって異なることがある。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 213,
        "shikenId": 2,
        "parentNo": 1,
        "no": 13,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "債券の信用格付けがダブルＢ以下である場合、一般的に投資不適格債と判断される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 214,
        "shikenId": 2,
        "parentNo": 1,
        "no": 14,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "ゆうちょ銀行の預入限度額は、通常貯金と定期性貯金を合わせて１人2,600万円までとなっている。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 215,
        "shikenId": 2,
        "parentNo": 1,
        "no": 15,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "消費者契約法における保護の対象は、個人が締結した契約となるため、事業のために締結した契約は対象外となっている。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 216,
        "shikenId": 2,
        "parentNo": 1,
        "no": 16,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "給与所得者であっても年収が2,000万円を超える場合、確定申告をする必要がある。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 217,
        "shikenId": 2,
        "parentNo": 1,
        "no": 17,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "非永住者以外である居住者は、国内源泉所得のみについて所得税が課税される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 218,
        "shikenId": 2,
        "parentNo": 1,
        "no": 18,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "所得税において給与所得を計算する場合、勤務先から支払われる通勤手当は月額15万円を限度に非課税とされる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 219,
        "shikenId": 2,
        "parentNo": 1,
        "no": 19,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "公的年金等に係る雑所得の金額は、「収入金額－公的年金等控除額－50万円」の算式により計算される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 220,
        "shikenId": 2,
        "parentNo": 1,
        "no": 20,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "「ふるさと納税ワンストップ特例制度」の適用を受けるためには、適用を受けようとする年の寄付金の合計額が10万円以下でなければならない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 221,
        "shikenId": 2,
        "parentNo": 1,
        "no": 21,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "土地の賃借権に関する登記がない場合でも、借地上の建物に関する登記があれば第三者に対して土地の賃借権を対抗することができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 222,
        "shikenId": 2,
        "parentNo": 1,
        "no": 22,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "不動産の売買契約を解除する場合、相手方が契約の履行に着手していなければ、買主は手付金の放棄、売主はその同額を現実に提供することにより契約を解除することができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 223,
        "shikenId": 2,
        "parentNo": 1,
        "no": 23,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "相続により不動産を取得した場合、不動産取得税は課税されない。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 224,
        "shikenId": 2,
        "parentNo": 1,
        "no": 24,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "不動産の仮登記を済ませておけば、順位保全の効力だけでなく、第三者への対抗力も認められる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 225,
        "shikenId": 2,
        "parentNo": 1,
        "no": 25,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "定期建物賃貸借契約（定期借家権）の場合、契約期間を１年以上としなければ、契約は無効となる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 226,
        "shikenId": 2,
        "parentNo": 1,
        "no": 26,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "特別養子縁組によって養子になった場合、実父母との法律上の親族関係は終了する。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 227,
        "shikenId": 2,
        "parentNo": 1,
        "no": 27,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "成年後見制度には法定後見制度と任意後見制度があるが、任意後見制度では本人の判断能力の程度などに応じて「後見」 「保佐」 「補助」の３類型に区分して支援される。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 228,
        "shikenId": 2,
        "parentNo": 1,
        "no": 28,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "使用貸借によって父が所有する土地を子が無償で借り、その土地の上に建物を建築した場合、父から子へ借地権の贈与があったものとみなされる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 229,
        "shikenId": 2,
        "parentNo": 1,
        "no": 29,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "民法における非嫡出子の法定相続分は、嫡出子の２分の１とされている。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 2,
        "score": 1
    },
    {
        "id": 230,
        "shikenId": 2,
        "parentNo": 1,
        "no": 30,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章を読んで、正しいものまたは適切なものには○を、誤っているものまたは不適切なものには✕をつけなさい。",
        "question2": "贈与契約を口頭によって締結した場合、まだ履行していない部分に限り取り消すことができる。",
        "choice1": "○",
        "choice2": "✕",
        "choice3": "",
        "answer": 1,
        "score": 1
    },
    {
        "id": 231,
        "shikenId": 2,
        "parentNo": 2,
        "no": 31,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "国民健康保険や健康保険の被保険者（障害状態にない）は、（　）になると後期高齢者医療制度の被保険者となる。",
        "choice1": "1）65歳",
        "choice2": "2）70歳",
        "choice3": "3）75歳",
        "answer": 3,
        "score": 1
    },
    {
        "id": 232,
        "shikenId": 2,
        "parentNo": 2,
        "no": 32,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "元金1,000,000円を利率２％で複利運用しながら20年間にわたって毎年均等に取り崩して受取る場合、毎年の受取金額を下記の〈資料〉を利用して算出すると（　）となる。<br> 〈資料〉利率（年率）２％・期間20年の各種係数<br><img src='/img/234.png' style='width:550px'/>",
        "choice1": "1）14,840円",
        "choice2": "2）61,160円",
        "choice3": "3）82,640円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 233,
        "shikenId": 2,
        "parentNo": 2,
        "no": 33,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "日本学生支援機構が取り扱う奨学金（貸与型）には、（ ① ）の第一種奨学金と（ ② ）の第二種奨学金がある。",
        "choice1": "1）① 無利息 ② 利息付",
        "choice2": "2）① 利息付 ② 無利息",
        "choice3": "3）① 返済不要 ② 無利息",
        "answer": 1,
        "score": 1
    },
    {
        "id": 234,
        "shikenId": 2,
        "parentNo": 2,
        "no": 34,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "雇用保険から支給される教育訓練給付金のうち、一般教育訓練を受講したことにより受取る給付金の額は、受講費用の（ ① ）で、上限額は（ ② ）となっている。",
        "choice1": "1）① 10％ ② ５万円",
        "choice2": "2）① 20％ ② 10万円",
        "choice3": "3）① 30％ ② 15万円",
        "answer": 2,
        "score": 1
    },
    {
        "id": 235,
        "shikenId": 2,
        "parentNo": 2,
        "no": 35,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "所得税の計算において、国民年金基金に対して拠出した掛金は、（　）として所得控除の対象となる。",
        "choice1": "1）小規模共済等掛金控除",
        "choice2": "2）生命保険料控除",
        "choice3": "3）社会保険料控除",
        "answer": 3,
        "score": 1
    },
    {
        "id": 236,
        "shikenId": 2,
        "parentNo": 2,
        "no": 36,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "ソルベンシー・マージン比率とは、保険会社の保険金に対する支払い余力をあらわしたものであり、この比率が（　）以上であれば健全性について１つの基準を満たしているといえる。",
        "choice1": "1）50％",
        "choice2": "2）100％",
        "choice3": "3）200％",
        "answer": 3,
        "score": 1
    },
    {
        "id": 237,
        "shikenId": 2,
        "parentNo": 2,
        "no": 37,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "一般にがん保険では入院給付日数が無制限となっているが、責任開始日の前に約（　）の免責期間が設けられている。",
        "choice1": "1）１カ月",
        "choice2": "2）３カ月",
        "choice3": "3）６カ月",
        "answer": 2,
        "score": 1
    },
    {
        "id": 238,
        "shikenId": 2,
        "parentNo": 2,
        "no": 38,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "ホテルのクロークで預かっていた顧客の荷物が盗難に遭ったことにより負担する法律上の賠償責任に備えるには（　）に加入する必要がある。",
        "choice1": "1）受託者賠償責任保険",
        "choice2": "2）施設所有者賠償責任保険",
        "choice3": "3）生産物賠償責任保険",
        "answer": 1,
        "score": 1
    },
    {
        "id": 239,
        "shikenId": 2,
        "parentNo": 2,
        "no": 39,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "民法および失火の責任に関する法律（失火責任法）において、借家人が軽過失により火事を起こし、借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を（ ① ）。また、隣家の所有者に対して損害賠償責任を（ ② ）。",
        "choice1": "1）① 負う ② 負わない",
        "choice2": "2）① 負う ② 負う",
        "choice3": "3）① 負わない ② 負う",
        "answer": 1,
        "score": 1
    },
    {
        "id": 240,
        "shikenId": 2,
        "parentNo": 2,
        "no": 40,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "被保険者の死亡後、契約で定めた期間分の年金が遺族に支払われる保険を（　）という。",
        "choice1": "1）所得補償保険",
        "choice2": "2）収入（生活）保障保険",
        "choice3": "3）特定疾病保障保険",
        "answer": 2,
        "score": 1
    },
    {
        "id": 241,
        "shikenId": 2,
        "parentNo": 2,
        "no": 41,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "個人向け国債は、変動金利の商品である（ ① ）、固定金利の商品である（ ② ）の３種類がある。変動金利商品の適用金利は半年に１度見直しとなっている。",
        "choice1": "1）① 変動３年・変動５年 ② 固定１年",
        "choice2": "2）① 変動５年 ② 固定１年・固定３年",
        "choice3": "3）① 変動10年 ② 固定３年・固定５年",
        "answer": 3,
        "score": 1
    },
    {
        "id": 242,
        "shikenId": 2,
        "parentNo": 2,
        "no": 42,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "デリバティブ取引などを利用して、ベンチマークする指数に対して何倍もの投資成果を得ることを目的として運用され、相場の（ ① ）局面で利益が得られるように設定される投資信託を（ ② ）という。",
        "choice1": "1）① 下落 ② ベア型ファンド",
        "choice2": "2）① 下落 ② ブル型ファンド",
        "choice3": "3）① 上昇 ② ベア型ファンド",
        "answer": 1,
        "score": 1
    },
    {
        "id": 243,
        "shikenId": 2,
        "parentNo": 2,
        "no": 43,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "外貨預金に預入をするため、円を外貨に換える場合は、（ ① ）レートを用いて計算を行う。また、払出の際に外貨を円に換える場合は、（ ② ）レートを用いて計算を行う。",
        "choice1": "1）① ＴＴＢ ② ＴＴＳ",
        "choice2": "2）① ＴＴＭ ② ＴＴＳ",
        "choice3": "3）① ＴＴＳ ② ＴＴＢ",
        "answer": 3,
        "score": 1
    },
    {
        "id": 244,
        "shikenId": 2,
        "parentNo": 2,
        "no": 44,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "投資信託の銘柄選択方法である（ ① ）とは、マクロ経済の動向を分析して投資対象を選択する方法である。一方、（ ② ）とは、個別企業へのリサーチなどによる情報を基に投資魅力の高い銘柄を選択する方法である。",
        "choice1": "1）① インデックス・アプローチ ② アクティブ・アプローチ",
        "choice2": "2）① トップダウン・アプローチ ② ボトムアップ・アプローチ",
        "choice3": "3）① グロース・アプローチ ② バリュー・アプローチ",
        "answer": 2,
        "score": 1
    },
    {
        "id": 245,
        "shikenId": 2,
        "parentNo": 2,
        "no": 45,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "2,000,000円を年利率３％（１年複利）で３年間運用した場合の元利合計金額は、手数料や税金等を考慮しない場合、（　）となる。",
        "choice1": "1）2,083,654円",
        "choice2": "2）2,121,800円",
        "choice3": "3）2,185,454円",
        "answer": 3,
        "score": 1
    },
    {
        "id": 246,
        "shikenId": 2,
        "parentNo": 2,
        "no": 46,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "2024年分の所得税について扶養控除の適用を受ける場合、配偶者以外の同一生計親族で年齢が（ ① ）の者が対象となり、原則38万円の控除が受けられる。また、（ ② ）の者は、特定扶養親族となり、63万円の控除が受けられる。",
        "choice1": "1）① 12歳以上 ② 16歳以上22歳未満",
        "choice2": "2）① 16歳以上 ② 19歳以上23歳未満",
        "choice3": "3）① 18歳以上 ② 20歳以上24歳未満",
        "answer": 2,
        "score": 1
    },
    {
        "id": 247,
        "shikenId": 2,
        "parentNo": 2,
        "no": 47,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "所得税の計算において医療費控除の計算をする際、（　）は医療費控除の対象とならない。",
        "choice1": "1）治療のための入院中の食事代",
        "choice2": "2）薬局で購入した風邪薬の購入費",
        "choice3": "3）コンタクトレンズや近視、乱視によるメガネの購入費用",
        "answer": 3,
        "score": 1
    },
    {
        "id": 248,
        "shikenId": 2,
        "parentNo": 2,
        "no": 48,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "配当所得を確定申告する場合、（ ① ）にて確定申告をすると配当控除の適用を受けることができる。<br> 配当控除の金額は、配当所得の（ ② ）で計算した金額が控除額となる。<br> また、課税総所得金額等が、1,000万円を超える場合、その超える部分については、配当所得の５％で計算した金額が控除額となる。",
        "choice1": "1）① 総合課税 ② 10％",
        "choice2": "2）① 申告分離課税 ② 15％",
        "choice3": "3）① 源泉分離課税 ② 20.315％",
        "answer": 1,
        "score": 1
    },
    {
        "id": 249,
        "shikenId": 2,
        "parentNo": 2,
        "no": 49,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "青色申告者である者に損益通算しても控除しきれない損失の金額（純損失の金額）が生じた場合、所定の要件を満たすことで、その損失の金額を翌年以後（　）にわたって繰り越すことができる。",
        "choice1": "1）２年間",
        "choice2": "2）３年間",
        "choice3": "3）４年間",
        "answer": 2,
        "score": 1
    },
    {
        "id": 250,
        "shikenId": 2,
        "parentNo": 2,
        "no": 50,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "損失が生じた場合、他の所得と損益通算できる所得は、（　）の４つの所得に限られている。",
        "choice1": "1）不動産所得・事業所得・山林所得・一時所得",
        "choice2": "2）不動産所得・一時所得・雑所得・譲渡所得",
        "choice3": "3）不動産所得・事業所得・山林所得・譲渡所得",
        "answer": 3,
        "score": 1
    },
    {
        "id": 251,
        "shikenId": 2,
        "parentNo": 2,
        "no": 51,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "取得費が不明である土地を譲渡した場合、譲渡所得の計算は概算取得費を用いて計算するが、概算取得費は譲渡対価に（　）を乗じた金額となる。",
        "choice1": "1）５％",
        "choice2": "2）15％",
        "choice3": "3）20％",
        "answer": 1,
        "score": 1
    },
    {
        "id": 252,
        "shikenId": 2,
        "parentNo": 2,
        "no": 52,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "固定資産税における課税標準の特例では、小規模住宅用地（住宅１戸につき200㎡以下の部分）については、課税標準が（　）となる。",
        "choice1": "1）２分の１",
        "choice2": "2）３分の１",
        "choice3": "3）６分の１",
        "answer": 3,
        "score": 1
    },
    {
        "id": 253,
        "shikenId": 2,
        "parentNo": 2,
        "no": 53,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "道路の幅員が４ｍ未満で特定行政庁から指定を受けている道路（２項道路）は、原則として道路の中心線から水平距離（ ① ）の線が道路の境界線とみなされる。<br> このセットバック部分は、建蔽率や容積率を計算する際、敷地面積に（ ② ）。",
        "choice1": "1）① 1.5ｍ ② 算入する",
        "choice2": "2）① ２ｍ ② 算入する",
        "choice3": "3）① ２ｍ ② 算入しない",
        "answer": 3,
        "score": 1
    },
    {
        "id": 254,
        "shikenId": 2,
        "parentNo": 2,
        "no": 54,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "「特定居住用財産の譲渡損失の損益通算及び繰越控除の特例」の適用を受けた場合、損益通算をしても控除しきれなかった譲渡損失の金額は、譲渡した年の翌年以降（　）にわたり繰越控除が受けられる。",
        "choice1": "1）１年間",
        "choice2": "2）３年間",
        "choice3": "3）５年間",
        "answer": 2,
        "score": 1
    },
    {
        "id": 255,
        "shikenId": 2,
        "parentNo": 2,
        "no": 55,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "農地を宅地に転用する場合、原則、都道府県知事の許可が必要となるが、市街化区域内の一定の農地を宅地に転用する場合については、あらかじめ（　）へ届出をすれば許可は不要となる。",
        "choice1": "1）農業委員会",
        "choice2": "2）市区町村長",
        "choice3": "3）都道府県知事",
        "answer": 1,
        "score": 1
    },
    {
        "id": 256,
        "shikenId": 2,
        "parentNo": 2,
        "no": 56,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "業務上の死亡により、遺族が雇用主から受ける弔慰金については、被相続人の死亡時における普通給与の（　）に相当する金額までは相続税がかからない。",
        "choice1": "1）１カ月分",
        "choice2": "2）６カ月分",
        "choice3": "3）３年分",
        "answer": 3,
        "score": 1
    },
    {
        "id": 257,
        "shikenId": 2,
        "parentNo": 2,
        "no": 57,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "遺留分侵害額の請求権は、相続の開始および遺留分を侵害する贈与または遺贈があったことを知った時から（ ① ）、または相続開始の時から（ ② ）を経過すると時効により消滅する。",
        "choice1": "1）① ３カ月間 ② １年間",
        "choice2": "2）① １年間 ② 10年間",
        "choice3": "3）① ３年間 ② ５年間",
        "answer": 2,
        "score": 1
    },
    {
        "id": 258,
        "shikenId": 2,
        "parentNo": 2,
        "no": 58,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "共同相続人の１人または数人が相続により相続財産の現物を取得し、その者が他の相続人に対して債務を負担する分割の方法を（　）という。",
        "choice1": "1）換価分割",
        "choice2": "2）代償分割",
        "choice3": "3）現物分割",
        "answer": 2,
        "score": 1
    },
    {
        "id": 259,
        "shikenId": 2,
        "parentNo": 2,
        "no": 59,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "相続の放棄をする者は、相続の開始があったことを知った時から（ ① ）以内に（ ② ）で家庭裁判所に申述する必要がある。",
        "choice1": "1）① １カ月 ② 相続人全員",
        "choice2": "2）① ３カ月 ② １人または数人",
        "choice3": "3）① ６カ月 ② 相続人全員",
        "answer": 2,
        "score": 1
    },
    {
        "id": 260,
        "shikenId": 2,
        "parentNo": 2,
        "no": 60,
        "groupId": 1,
        "answerType": 1,
        "question1": "次の文章の（　）内にあてはまる最も適切な文章、語句、数字またはそれらの組合せを１）〜３）のなかから選びなさい。",
        "question2": "公正証書遺言は作成する際に証人の立会が（ ① ）となる。<br> なお、遺言者の相続開始時には家庭裁判所の検認を受ける（ ② ）。",
        "choice1": "1）① 必要 ② 必要がある",
        "choice2": "2）① 不要 ② 必要がある",
        "choice3": "3）① 必要 ② 必要がない",
        "answer": 3,
        "score": 1
    },
    {
        "id": 261,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "現時点（2024年９月11日）においてＡさんが死亡した場合、妻Ｂさんに支給される遺族基礎年金の年金額（2024年度価額）は、次のうちどれか。",
        "choice1": "816,000円＋78,300円＋78,300円＝972,600円",
        "choice2": "816,000円＋234,800円＋78,300円＝1,129,100円",
        "choice3": "816,000円＋234,800円＋234,800円＝1,285,600円",
        "answer": 3,
        "score": 3,
        "image": "5-1.png"
    },
    {
        "id": 262,
        "shikenId": 2,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "Ｍさんは、現時点（2024年９月11日）においてＡさんが死亡した場合に、妻Ｂさんに支給される遺族厚生年金の金額等について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br> <br><div class='b-box'>「遺族厚生年金の額は、原則として、Ａさんの厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の（ ① ）相当額となります。ただし、Ａさんの場合、その計算の基礎となる被保険者期間の月数が（ ② ）月に満たないため、（ ② ）月とみなして年金額が計算されます。<br> また、二男Ｄさんの18歳到達年度の末日が終了し、妻Ｂさんの有する遺族基礎年金の受給権が消滅したときは、妻Ｂさんが65歳に達するまでの間、妻Ｂさんに支給される遺族厚生年金（ ③ ）が加算されます」</div>",
        "choice1": "① ３分の２ ② 240 ③ 中高齢寡婦加算",
        "choice2": "① ４分の３ ② 300 ③ 中高齢寡婦加算",
        "choice3": "① ４分の３ ② 240 ③ 経過的寡婦加算",
        "answer": 2,
        "score": 4,
        "image": "5-1.png"
    },
    {
        "id": 263,
        "shikenId": 2,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 2,
        "question1": "",
        "question2": "Ｍさんは、公的介護保険（以下、「介護保険」という）について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「介護保険の被保険者が保険給付を受けるためには、市町村（特別区を含む）から要介護・要支援認定を受ける必要があります。介護保険の被保険者は、（ ① ）以上の第１号被保険者と40歳以上（ ① ）未満の医療保険加入者である第２号被保険者に区分されます。介護保険の第２号被保険者は、（ ② ）要介護状態または要支援状態となった場合に保険給付を受けることができます。<br> 介護保険の第２号被保険者が介護給付を受けた場合、原則として、実際にかかった費用（食費、居住費等を除く）の（ ③ ）を自己負担する必要があります」</div>",
        "choice1": "① 60歳 ② 特定疾病が原因で ③ ２割",
        "choice2": "① 65歳 ② 原因を問わず ③ ２割",
        "choice3": "① 65歳 ② 特定疾病が原因で ③ １割",
        "answer": 3,
        "score": 3,
        "image": "5-1.png"
    },
    {
        "id": 264,
        "shikenId": 2,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "はじめに、Ｍさんは、Ｘ社株式の投資指標について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「株価の相対的な割高・割安の度合いを判断する指標に、ＰＥＲやＰＢＲがあります。＜Ｘ社に関する資料＞から算出されるＸ社株式のＰＥＲは1.2倍、ＰＢＲは12倍です」",
        "choice2": "「ＰＥＲとＰＢＲは、一般に、どちらも数値が高いほうが株価は割安と判断されますが、何倍程度が妥当であるかを検討する際は、同業他社の数値や過去の傾向と比較するなど、相対的な数値として捉えることが重要です」",
        "choice3": "「株価に対する１株当たりの年間配当金の割合を示す指標を配当利回りといいます。＜Ｘ社に関する資料＞から算出されるＸ社株式の配当利回りは2.5％です」",
        "answer": 3,
        "score": 4,
        "image": "5-2.png"
    },
    {
        "id": 265,
        "shikenId": 2,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "次に、Ｍさんは、Ｙ投資信託の購入に係る費用等について説明した。ＭさんのＡさ んに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ｙ投資信託の購入時に手数料はかかりません。新しいNISAの対象となる公募株式投資信託は、購入時手数料がゼロであることが要件の１つとなっています」",
        "choice2": "「運用管理費用（信託報酬）は、投資信託を保有する投資家が間接的に負担する費用です。一般に、Ｙ投資信託のようなインデックス型投資信託は、アクティブ型投資信託よりも運用管理費用（信託報酬）が高い傾向があります」",
        "choice3": "「信託財産留保額は、投資信託の組入資産を売却する際に発生する手数料等を、投資信託を換金する投資家に負担してもらうことを目的として設けられているものですが、Ｙ投資信託では、この費用はかかりません」",
        "answer": 2,
        "score": 3,
        "image": "5-2.png"
    },
    {
        "id": 266,
        "shikenId": 2,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 2,
        "question1": "",
        "question2": "最後に、Ｍさんは、「新しいNISA」についてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も適切なものはどれか。",
        "choice1": "「新しいNISAのつみたて投資枠の対象となる金融商品は、長期の積立・分散投資に適した一定の商品性を有する公募株式投資信託やＥＴＦとされ、上場株式は対象となっていません」",
        "choice2": "「新しいNISAの年間のつみたて投資枠における非課税投資枠は40万円です。購入は累積投資契約に基づく定期かつ継続的な買付けを行う方法に限られており、非課税期間は10年間です」",
        "choice3": "「新しいNISAを利用して購入した公募株式投資信託を解約した際に損失が生じた場合、その損失の金額は、特定口座で保有する上場株式等の譲渡益と通算することができます」",
        "answer": 1,
        "score": 3,
        "image": "5-2.png"
    },
    {
        "id": 267,
        "shikenId": 2,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。",
        "choice1": "563万円",
        "choice2": "588万円",
        "choice3": "638万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
        "answer": 1,
        "score": 4,
        "image": "5-3.png"
    },
    {
        "id": 268,
        "shikenId": 2,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における所得控除に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>ⅰ）「Ａさんが適用を受けることができる配偶者控除の額は、（ ① ）万円です」<br> ⅱ）「長女Ｃさんの合計所得金額は（ ② ）万円を超えますので、Ａさんは長女Ｃさんに係る扶養控除の適用を受けることはできません」<br> ⅲ）「Ａさんが適用を受けることができる長男Ｄさんに係る扶養控除の額は、（ ③ ）万円です」</div>",
        "choice1": "① 38 ② 48  ③ 63",
        "choice2": "① 38 ② 103 ③ 48",
        "choice3": "① 48 ② 103 ③ 63",
        "answer": 1,
        "score": 3,
        "image": "5-3.png"
    },
    {
        "id": 269,
        "shikenId": 2,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における医療費控除および確定申告に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが、妻Ｂさんの通院時に自家用車で送迎していた場合、その際にかかったガソリン代や駐車料金は、医療費控除の対象となる医療費の範囲に含まれます」",
        "choice2": "「Ａさんが2024年中に支払った医療費の総額が20万円を超えていない場合、医療費控除額は算出されません」",
        "choice3": "「Ａさんは、医療費控除の適用を受けない場合であっても、総所得金額に算入される一時所得の金額が20万円を超えるため、所得税の確定申告をしなければなりません」",
        "answer": 3,
        "score": 3,
        "image": "5-3.png"
    },
    {
        "id": 270,
        "shikenId": 2,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "甲土地に耐火建築物を建築する場合の①建蔽率の上限となる建築面積と②容積率の上限となる延べ面積の組合せとして、次のうち最も適切なものはどれか。",
        "choice1": "① 360㎡ ② 1,440㎡",
        "choice2": "① 360㎡ ② 1,200㎡",
        "choice3": "① 400㎡ ② 1,200㎡",
        "answer": 2,
        "score": 4,
        "image": "5-4.png"
    },
    {
        "id": 271,
        "shikenId": 2,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "自宅（建物およびその敷地である甲土地）の譲渡に関する以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。<br> <br> <div class='b-box'>ⅰ）「仮に、Ａさんがタワーマンションに転居し、その後、居住していない現在の自宅を譲渡した場合に、Ａさんが『居住用財産を譲渡した場合の3,000万円の特別控除の特例』の適用を受けるためには、家屋に自己が居住しなくなった日から（ ① ）を経過する日の属する年の12月31日までの譲渡であること等の要件を満たす必要があります。また、『居住用財産を譲渡した場合の長期譲渡所得の課税の特例』の適用を受けた場合、課税長期譲渡所得金額が（ ② ）以下の部分については、軽減税率が適用されます」<br> ⅱ）「Ａさんが自宅を譲渡し、マンションを購入した場合、譲渡した年の１月１日において譲渡した居住用財産の所有期間が（ ③ ）を超えていること等の要件を満たせば、『特定の居住用財産の買換えの場合の長期譲渡所得の課税の特例』の適用を受けることができます」</div>",
        "choice1": "① ３年 ② １億円  ③ ５年",
        "choice2": "① ５年 ② １億円  ③ 10年",
        "choice3": "① ３年 ② 6,000万円 ③ 10年",
        "answer": 3,
        "score": 3,
        "image": "5-4.png"
    },
    {
        "id": 272,
        "shikenId": 2,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 2,
        "question1": "",
        "question2": "自己建設方式による甲土地の有効活用に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「Ａさんが甲土地に賃貸マンションを建築した場合、相続税の課税価格の計算上、甲土地は貸宅地として評価されます」",
        "choice2": "「甲土地が貸付事業用宅地等に該当すれば、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けることができます。貸付事業用宅地等は、相続税の課税価格の計算上、200㎡までの部分について50％の減額が受けられます」",
        "choice3": "「賃貸マンションを建築することで相続税等の軽減が期待できますが、将来の賃料収入が減少することや、借入金の返済が滞ることなどのリスクを考慮し、実行にあたっては慎重な計画が求められます」",
        "answer": 1,
        "score": 3,
        "image": "5-4.png"
    },
    {
        "id": 273,
        "shikenId": 2,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの相続等に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "「遺言により妻Ｂさんおよび長女Ｃさんが相続財産の大半を取得した場合、二女Ｄさんの遺留分を侵害する可能性があります。仮に、遺留分を算定するための財産の価額が３億円である場合、二女Ｄさんの遺留分の金額は7,500万円となります」",
        "choice2": "「死亡保険金を活用した代償分割の方法を考えた場合、契約者（＝保険料負担者）および被保険者をＡさん、死亡保険金受取人を長女Ｃさんとする終身保険に加入することも検討事項の１つとなります」",
        "choice3": "「遺産分割をめぐる争いを防ぐ手段として、公正証書遺言の作成をお勧めします。公正証書遺言は証人２人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がこれを筆記して作成します」",
        "answer": 1,
        "score": 3,
        "image": "5-5.png"
    },
    {
        "id": 274,
        "shikenId": 2,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "仮に、Ａさんの相続が現時点（2024年５月23日）で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が２億4,000万円であった場合の相続税の総額は、次のうちどれか。",
        "choice1": "2,200万円",
        "choice2": "5,300万円",
        "choice3": "8,100万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
        "answer": 2,
        "score": 4,
        "image": "5-5.png"
    },
    {
        "id": 275,
        "shikenId": 2,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 2,
        "question1": "",
        "question2": "Ａさんの相続に係る小規模宅地等についての相続税の課税価格の計算の特例（以下、「本特例」という）に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「妻Ｂさんが自宅の敷地を相続により取得し、特定居住用宅地等として本特例の適用を受けた場合、330㎡までの部分について80％の減額が受けられます」",
        "choice2": "「妻Ｂさんが自宅の敷地を相続により取得し、相続税の申告期限までに自宅の敷地を売却した場合、当該敷地は特定居住用宅地等として本特例の適用を受けることができなくなります」",
        "choice3": "「自宅の敷地と賃貸ビルの敷地について、本特例の適用を受けようとする場合、適用対象面積の調整はせず、それぞれの宅地等の適用対象の限度面積まで適用を受けることができます」",
        "answer": 1,
        "score": 3,
        "image": "5-5.png"
    },
    {
        "id": 276,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "はじめに、Ｍさんは、老齢基礎年金について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。<br> <br> 「老齢基礎年金を受給するためには、原則として、国民年金の保険料納付済期間と保険料免除期間を合算した期間が（ ① ）年必要です。Ａさんは、（ ① ）年の受給資格期間を満たしていますので、原則として65歳から老齢基礎年金を受給することができます。<br> Ａさんが希望すれば、60歳以上65歳未満の間に老齢基礎年金の繰上げ支給を請求することができます。ただし、老齢基礎年金の繰上げ支給を請求した場合は、（ ② ）減額された年金が支給されることになります。仮に、Ａさんが60歳０カ月で老齢基礎年金の繰上げ支給を請求した場合の減額率は（ ③ ）％となります」",
        "choice1": "① 10 ② 生涯  ③ 24",
        "choice2": "① 10 ② 75歳まで ③ 30",
        "choice3": "① 25 ② 生涯  ③ 30",
        "answer": 1,
        "score": 3,
        "image": "6-1.png"
    },
    {
        "id": 277,
        "shikenId": 2,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "次に、Ｍさんは、国民年金の付加保険料について説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「仮に、Ａさんが付加保険料を145月納付し、65歳から老齢基礎年金を受け取る場合、老齢基礎年金の額に付加年金として年額29,000円が上乗せされます」",
        "choice2": "「老齢基礎年金の繰上げ支給を請求した場合でも、付加年金の年金額は減額されません」",
        "choice3": "「国民年金の第１号被保険者は、国民年金基金に加入することができますが、国民年金基金に加入した場合は、付加保険料を納付することができません」",
        "answer": 2,
        "score": 4,
        "image": "6-1.png"
    },
    {
        "id": 278,
        "shikenId": 2,
        "parentNo": 1,
        "no": 3,
        "groupId": 1,
        "answerType": 3,
        "question1": "",
        "question2": "最後に、Ｍさんは、小規模企業共済制度について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句または数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「小規模企業共済制度は、個人事業主が廃業等した場合に必要となる資金を準備しておくための制度です。毎月の掛金は、1,000円から（ ① ）円までの範囲内（500円単位）で選択でき、支払った掛金は（ ② ）の対象となります。共済金（死亡事由以外）の受取方法には『一括受取り』 『分割受取り』 『一括受取りと分割受取りの併用』がありますが、このうち、『一括受取り』の共済金（死亡事由以外）は、（ ③ ）として所得税の課税対象となります」</div>",
        "choice1": "① 70,000 ② 税額控除 ③ 一時所得",
        "choice2": "① 68,000 ② 税額控除 ③ 退職所得",
        "choice3": "① 70,000 ② 所得控除 ③ 退職所得",
        "answer": 3,
        "score": 3,
        "image": "6-1.png"
    },
    {
        "id": 279,
        "shikenId": 2,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "はじめに、Ｍさんは、Ａさんが個人事業主となった場合の社会保険の取扱いについて説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「Ａさんは、退職日の翌日から60歳になるまでの間、健康保険に任意継続被保険者として加入することができます」",
        "choice2": "「Ａさんが国民健康保険に加入した場合、妻Ｂさんを国民健康保険の被扶養者とすることができます」",
        "choice3": "「ＡさんがＸ社を退職し、厚生年金保険の被保険者でなくなった場合、妻Ｂさんは、国民年金の第３号被保険者から第１号被保険者への種別変更の届出を行い、国民年金の保険料を納付することになります」",
        "answer": 3,
        "score": 3,
        "image": "6-2.png"
    },
    {
        "id": 280,
        "shikenId": 2,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "次に、Ｍさんは、Ａさんに想定されるリスクについて説明した。ＭさんのＡさんに対する説明として、次のうち最も不適切なものはどれか。",
        "choice1": "「Ａさんのような公的介護保険の第２号被保険者は、要介護状態または要支援状態となった原因が特定疾病によって生じたものでなければ、公的介護保険からの保険給付は受けられません」",
        "choice2": "「個人事業主が加入する国民健康保険には、高額療養費制度が設けられていないため、会社員に比べて医療費の自己負担額が多くなる傾向があります。そのため、ケガや病気の場合の治療費の準備を充実させることをご検討ください」",
        "choice3": "「個人事業主となったＡさんが生活習慣病等で働けなくなった場合、会社員のときよりも収入が減少するリスクが高くなると思われます。そのため、治療費の準備に加えて、働けなくなった場合の所得補償の準備もご検討ください」",
        "answer": 2,
        "score": 3,
        "image": "6-2.png"
    },
    {
        "id": 281,
        "shikenId": 2,
        "parentNo": 1,
        "no": 6,
        "groupId": 2,
        "answerType": 3,
        "question1": "",
        "question2": "最後に、Ｍさんは、生命保険の見直しについてアドバイスした。ＭさんのＡさんに対するアドバイスとして、次のうち最も不適切なものはどれか。",
        "choice1": "「会社員と個人事業主とでは、妻Ｂさんが受け取る公的年金等の総額や、死亡退職金の有無など、必要保障額を計算する際の条件が異なります。Ａさんが個人事業主となった場合の必要保障額を計算することをお勧めします」",
        "choice2": "「最近では、入院１日目から相応の一時金が支払われるタイプや、退院後の通院治療のために給付金が支払われるタイプの保険（特約）が販売されています。保険会社各社の保障内容をよく比較して、見直しを検討しましょう」",
        "choice3": "「現在加入している生命保険を払済終身保険に変更した場合、死亡保険金額は減少しますが、現在付加されている入院特約は残り、月々の保険料負担は軽減されます」",
        "answer": 3,
        "score": 4,
        "image": "6-2.png"
    },
    {
        "id": 282,
        "shikenId": 2,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "仮に、将来Ｘ社がＡさんに役員退職金5,000万円を支給した場合、Ａさんが受け取る役員退職金に係る退職所得の金額として、次のうち最も適切なものはどれか。なお、Ａさんの役員在任期間（勤続年数）を38年とし、これ以外に退職手当等の収入はなく、障害者になったことが退職の直接の原因ではないものとする。",
        "choice1": "1,170万円",
        "choice2": "1,470万円",
        "choice3": "2,940万円",
        "answer": 2,
        "score": 3,
        "image": "6-3.png"
    },
    {
        "id": 283,
        "shikenId": 2,
        "parentNo": 1,
        "no": 8,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは《設例》の終身保険について説明した。ＭさんのＡさんに対する説明として、次のうち最も適切なものはどれか。",
        "choice1": "「当該終身保険の支払保険料は、その全額を損金の額に算入することができます」",
        "choice2": "「Ｘ社が保険期間中に資金が必要となった場合に、契約者貸付制度を利用することにより、当該終身保険契約を解約することなく、資金を調達することができます」",
        "choice3": "「Ａさんの勇退時に、契約者をＡさん、死亡保険金受取人をＡさんの相続人に名義変更して、当該終身保険契約を役員退職金の一部として現物支給した場合、契約は継続しているため、Ｘ社での経理処理は必要ありません」",
        "answer": 2,
        "score": 4,
        "image": "6-3.png"
    },
    {
        "id": 284,
        "shikenId": 2,
        "parentNo": 1,
        "no": 9,
        "groupId": 3,
        "answerType": 3,
        "question1": "",
        "question2": "Ｍさんは、中小企業退職金共済制度（以下、「中退共」という）の特徴について説明した。Ｍさんが、Ａさんに対して説明した以下の文章の空欄①～③に入る語句の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>「中退共は、中小企業の事業主が退職金を社外に積み立てる退職金準備の共済制度です。毎月の掛金は、原則として、被共済者（従業員）１人につき月額5,000円から30,000円のなかから任意に選択することができ、その（ ① ）を損金の額に算入することができます。また、新しく中退共に加入する事業主に対して、原則として、掛金月額の（ ② ）（従業員ごと上限5,000円）を加入後４カ月目から１年間、国が助成する制度があります。被共済者（従業員）が中途（生存）退職したときは、退職金が（ ③ ）支給されます」</div>",
        "choice1": "① 全額 ② ３分の１ ③ 法人を経由して従業員に",
        "choice2": "① ２分の１ ② ３分の１ ③ 従業員本人に直接",
        "choice3": "① 全額 ② ２分の１ ③ 従業員本人に直接",
        "answer": 3,
        "score": 3,
        "image": "6-3.png"
    },
    {
        "id": 285,
        "shikenId": 2,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における総所得金額は、次のうちどれか。 ",
        "choice1": "565万円",
        "choice2": "590万円",
        "choice3": "615万円<br><br>〈資料〉給与所得控除額<br><img src='/img/67_1.png' width='500'>",
        "answer": 2,
        "score": 4,
        "image": "6-4.png"
    },
    {
        "id": 286,
        "shikenId": 2,
        "parentNo": 1,
        "no": 11,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における所得控除の控除額に関する以下の文章の空欄①～③に入る数値の組合せとして、次のうち最も適切なものはどれか。 <br> <br> <div class='b-box'>ⅰ）「Ａさんが適用を受けることができる配偶者控除の控除額は、（ ① ）万円です」<br> ⅱ）「母Ｃさんは老人扶養親族の同居老親等に該当するため、Ａさんが適用を受けることができる扶養控除の控除額は、（ ② ）万円です」<br> ⅲ）「Ａさんが適用を受けることができる基礎控除の控除額は、（ ③ ）万円です」</div>",
        "choice1": "① 26 ② 38 ③ 48",
        "choice2": "① 26 ② 58 ③ 38",
        "choice3": "① 38 ② 58 ③ 48",
        "answer": 3,
        "score": 3,
        "image": "6-4.png"
    },
    {
        "id": 287,
        "shikenId": 2,
        "parentNo": 1,
        "no": 12,
        "groupId": 4,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの2024年分の所得税における生命保険料控除に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが加入した終身医療保険に係る保険料は、介護医療保険料控除の対象となります」",
        "choice2": "「Ａさんが生命保険料控除として総所得金額から控除することができる金額は、100,000円です」",
        "choice3": "「生命保険料控除は、生命保険に加入した年分については勤務先の年末調整で適用を受けることができず、所得税の確定申告が必要となります」",
        "answer": 1,
        "score": 3,
        "image": "6-4.png"
    },
    {
        "id": 288,
        "shikenId": 2,
        "parentNo": 1,
        "no": 13,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "生前贈与に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが暦年課税を選択した場合、その年にＡさんから長女Ｃさんへ贈与した財産の価額が贈与税の基礎控除額を超えるときは、贈与したＡさんが贈与税の申告書を提出しなければなりません」",
        "choice2": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、累計で3,000万円までの贈与について贈与税は課されません」",
        "choice3": "「Ａさんが長女Ｃさんに現金を贈与し、長女Ｃさんが相続時精算課税制度を選択した場合、その選択をした年分以降にＡさんから長女Ｃさんへ贈与する財産について、暦年課税へ変更することはできません」",
        "answer": 3,
        "score": 3,
        "image": "6-5.png"
    },
    {
        "id": 289,
        "shikenId": 2,
        "parentNo": 1,
        "no": 14,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "Ａさんの相続等に関する次の記述のうち、最も適切なものはどれか。",
        "choice1": "「妻Ｂさんが自宅の敷地を相続により取得、『小規模宅地等についての相続税の課税価格の計算の特例』の適用を受けた場合、自宅の敷地について相続税の課税価格に算入すべき価額は5,600万円となります」",
        "choice2": "「円滑な遺産分割のための手段として遺言書の作成をお勧めします。自筆証書遺言は、その遺言の全文および財産目録をパソコンで作成し、日付および氏名を自書して押印することで作成することができます」",
        "choice3": "「契約者（＝保険料負担者）および被保険者をＡさん、死亡保険金受取人を推定相続人とする終身保険に加入することをお勧めします。死亡保険金受取人が受け取る死亡保険金は、『500万円×法定相続人の数』を限度として、死亡保険金の非課税金額の規定の適用を受けることができます」",
        "answer": 3,
        "score": 3,
        "image": "6-5.png"
    },
    {
        "id": 290,
        "shikenId": 2,
        "parentNo": 1,
        "no": 15,
        "groupId": 5,
        "answerType": 3,
        "question1": "",
        "question2": "仮に、Ａさんの相続が現時点（2024年５月23日）で開始し、Ａさんの相続に係る課税遺産総額（課税価格の合計額－遺産に係る基礎控除額）が9,000万円であった場合の相続税の総額は、次のうちどれか。 ",
        "choice1": "1) 1,200万円",
        "choice2": "2) 1,275万円",
        "choice3": "3) 2,000万円<div class='pt-3'>        <img src='/img/88_2.png' width='420'/>      </div>",
        "answer": 2,
        "score": 4,
        "image": "6-5.png"
    },
    {
        "id": 291,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "groupId": 1,
        "answerType": 4,
        "question1": "",
        "question2": "ファイナンシャル・プランニング業務を行うに当たっては、関連業法を順守することが重要である。ファイナンシャル・プランナー（以下「ＦＰ」という）の行為に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "生命保険募集人、保険仲立人の登録を受けていないＦＰが、生命保険契約を検討している顧客のライフプランに基づき、必要保障額を具体的に試算し、相談料金を受け取った。",
        "choice2": "投資助言・代理業の登録を受けていないＦＰが、顧客と投資顧問契約を締結し、当該契約に基づいて具体的な投資銘柄と投資タイミングについて有償で助言をした。",
        "choice3": "税理士資格を有していないＦＰが、相続対策を検討している顧客に対し、一般的な相続税制度の仕組みと手順を解説し、相談料金を受け取った。",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 292,
        "shikenId": 2,
        "parentNo": 1,
        "no": 2,
        "groupId": 1,
        "answerType": 4,
        "question1": "",
        "question2": "下記は、服部家のキャッシュフロー表（一部抜粋）である。このキャッシュフロー表の空欄（ア）、（イ）にあてはまる数値の組み合わせとして、正しいものはどれか。なお、計算過程においては端数処理をせず計算し、計算結果については万円未満を四捨五入すること。<br><img src=\"/img/question/201_1.png\" class=\"question-img\" /><br> <br> ※年齢および金融資産残高は各年１２月３１日現在のものとし、２０２４年を基準年とする。<br> ※給与収入は可処分所得で記載している。<br> ※記載されている数値は正しいものとする。<br> ※問題作成の都合上、一部を空欄にしてある。",
        "choice1": "（ア）４１０ （イ）８７４",
        "choice2": "（ア）４１１ （イ）１,０５１",
        "choice3": "（ア）４２０ （イ）１,０５２",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 293,
        "shikenId": 2,
        "parentNo": 1,
        "no": 3,
        "groupId": 2,
        "answerType": 4,
        "question1": "長谷川大輔さんは株式会社ＰＥに勤務する会社員である。大輔さんは今後の生活設計について、ＦＰで税理士でもある上埜さんに相談をした。なお、下記のデータはいずれも２０２４年９月１日現在のものである。<br><img src=\"/img/question/202_1.png\" class=\"question-img\" style=\"width:340px\" /><br> <br> ［負債残高］<br> 住宅ローン（自宅マンション）：２,１００万円（債務者は大輔さん、団体信用生命保険付き）<br> <br> ［その他］<br> 上記以外については、特に指定のない限り一切考慮しないものとする。",
        "question2": "ＦＰの上埜さんは、長谷川家の２０２４年９月１日現在のバランスシートを作成した。下表の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、上記のデータに基づいて解答することとする。<br><img src=\"/img/question/202_2.png\" class=\"question-img\" />",
        "choice1": "１,２２０（万円）",
        "choice2": "１,９２０（万円）",
        "choice3": "１,９６０（万円）",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 294,
        "shikenId": 2,
        "parentNo": 1,
        "no": 4,
        "groupId": 2,
        "answerType": 4,
        "question1": "",
        "question2": "会社員の山田遼さんは、６０歳で定年を迎えた後、公的年金の支給が始まる６５歳までの５年間の生活資金に退職一時金の一部を充てようと考えている。仮に、退職一時金のうち６００万円を年利１.０％で複利運用しながら５年間で均等に取り崩すこととした場合、毎年の生活資金に充てることができる最大金額として、正しいものはどれか。なお、下記＜資料＞の３つの係数の中から最も適切な係数を選択して計算し、解答に当たっては万円未満を切り捨てること。また、税金や記載のない事項については一切考慮しないこととする。<br><img src=\"/img/question/203_1.png\" class=\"question-img\" />",
        "choice1": "１１４万円",
        "choice2": "１１７万円",
        "choice3": "１２３万円",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 295,
        "shikenId": 2,
        "parentNo": 1,
        "no": 5,
        "groupId": 2,
        "answerType": 4,
        "question1": "",
        "question2": "会社員の石戸修二さんは、通常６５歳から支給される老齢基礎年金および老齢厚生年金を繰り下げて受給できることを知り、ＦＰの矢尾さんに質問をした。老齢基礎年金および老齢厚生年金の繰下げ受給に関する次の記述のうち、最も不適切なものはどれか。なお、老齢基礎年金および老齢厚生年金の受給要件は満たしているものとする。",
        "choice1": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合の年金額は、繰下げ年数１年当たり７％の割合で増額された額となる。",
        "choice2": "老齢基礎年金と老齢厚生年金は、どちらか一方のみを繰り下げて受給することができる。",
        "choice3": "老齢基礎年金および老齢厚生年金を繰り下げて受給した場合には、一生涯増額された年金を受給することになる。",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 296,
        "shikenId": 2,
        "parentNo": 1,
        "no": 6,
        "groupId": 3,
        "answerType": 4,
        "question1": "",
        "question2": "下記＜資料＞は、ＭＸファンドの販売用資料（一部抜粋）である。この投資信託に関する次の記述のうち、最も不適切なものはどれか。<br><img src=\"/img/question/205_1.png\" class=\"question-img\" />",
        "choice1": "ＭＸファンドは、ＮＩＳＡ（少額投資非課税制度）口座で購入することができる",
        "choice2": "ＭＸファンドは、運用状況によっては収益分配金が支払われないこともある。",
        "choice3": "ＭＸファンドを購入する際、投資家が支払う購入代金は<br>「<img src=\"/img/question/205_2.png\" style=\"width:138px\" /> ×購入口数＋購入時手数料（税込）＋運用管理費用（信託報酬）（税込）」である。",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 297,
        "shikenId": 2,
        "parentNo": 1,
        "no": 7,
        "groupId": 3,
        "answerType": 4,
        "question1": "",
        "question2": "下記＜資料＞の外貨定期預金について、満期時の外貨ベースの元利合計額を円転した金額として、正しいものはどれか。なお、計算結果（円転した金額）について円未満の端数が生じる場合は切り捨てること。また、税金については考慮しないこととする。<br><img src=\"/img/question/206_1.png\" class=\"question-img\" />",
        "choice1": "７８２,５０５円",
        "choice2": "７７７,４８３円",
        "choice3": "７７２,４６０円",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 298,
        "shikenId": 2,
        "parentNo": 1,
        "no": 8,
        "groupId": 4,
        "answerType": 4,
        "question1": "",
        "question2": "小堀さんは、預金保険制度の対象となるＨＡ銀行の国内支店に下記＜資料＞の預金を預け入れている。<br> 仮に、ＨＡ銀行が破たんした場合、預金保険制度によって保護される金額に関する次の記述のうち、最も不適切なものはどれか。<br><img src=\"/img/question/207_1.png\" class=\"question-img\" />",
        "choice1": "決済用預金１,５００万円は全額保護される。",
        "choice2": "円定期預金および円普通預金は、合算し１,０００万円が保護される。",
        "choice3": "外貨預金２００万円は全額保護される。",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 299,
        "shikenId": 2,
        "parentNo": 1,
        "no": 9,
        "groupId": 4,
        "answerType": 4,
        "question1": "",
        "question2": "公的な土地評価に関する下表の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。<br><img src=\"/img/question/208_1.png\" class=\"question-img\" />",
        "choice1": "（ア）総務省 （イ）市町村（東京２３区は東京都） （ウ）７０％",
        "choice2": "（ア）国土交通省 （イ）市町村（東京２３区は東京都） （ウ）８０％",
        "choice3": "（ア）国土交通省 （イ）国税庁 （ウ）９０％",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 300,
        "shikenId": 2,
        "parentNo": 1,
        "no": 10,
        "groupId": 4,
        "answerType": 4,
        "question1": "",
        "question2": "下記＜資料＞の甲土地の建築面積の最高限度を算出する基礎となる敷地面積として、正しいものはどれか。なお、この土地の存する区域は特定行政庁が指定する区域に該当しないものとし、その他記載のない条件については一切考慮しないこととする。<br><img src=\"/img/question/209_1.png\" class=\"question-img\" />",
        "choice1": "１２０ｍ<sup>2</sup>",
        "choice2": "１８０ｍ<sup>2</sup>",
        "choice3": "１９０ｍ<sup>2</sup>",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 301,
        "shikenId": 2,
        "parentNo": 1,
        "no": 11,
        "groupId": 5,
        "answerType": 4,
        "question1": "",
        "question2": "下表は、定期借地権についてまとめた表である。下表の空欄（ア）～（ウ）にあてはまる数値または語句の組み合わせとして、最も適切なものはどれか。<br><img src=\"/img/question/210_1.png\" class=\"question-img\" />",
        "choice1": "（ア）３０ （イ）居住用 （ウ）借地人",
        "choice2": "（ア）５０ （イ）事業用 （ウ）土地所有者",
        "choice3": "（ア）５０ （イ）居住用 （ウ）土地所有者",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 302,
        "shikenId": 2,
        "parentNo": 1,
        "no": 12,
        "groupId": 5,
        "answerType": 4,
        "question1": "",
        "question2": "皆木慎太朗さんが加入している医療保険（下記＜資料＞参照）の保障内容に関する次の記述の空欄（ア）にあてはまる金額として、正しいものはどれか。なお、保険契約は有効に継続しているものとする。また、慎太朗さんはこれまでに＜資料＞の保険から保険金および給付金を一度も受け取っていないものとする。<br><img src=\"/img/question/211_1.png\" class=\"question-img\" />",
        "choice1": "３００,０００円",
        "choice2": "４００,０００円",
        "choice3": "４１５,０００円",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 303,
        "shikenId": 2,
        "parentNo": 1,
        "no": 13,
        "groupId": 6,
        "answerType": 4,
        "question1": "",
        "question2": "ＦＰの清野さんは相談者の角田さんから地震保険に関する質問を受けた。地震保険に関する清野さんの次の説明のうち、最も不適切なものはどれか。",
        "choice1": "「地震保険は、住宅総合保険や火災保険などとセットで契約するため、単独での契約はできません。」",
        "choice2": "「地震保険の保険料は保険会社ごとに異なるので、数社から見積りを取った方が良いでしょう。」",
        "choice3": "「噴火により、居住用の建物が全損となった場合、地震保険の補償の対象となります。」",
        "answer": 2,
        "score": 5,
        "image": ""
    },
    {
        "id": 304,
        "shikenId": 2,
        "parentNo": 1,
        "no": 14,
        "groupId": 6,
        "answerType": 4,
        "question1": "",
        "question2": "損害保険の用語に関する次の記述のうち、最も不適切なものはどれか。",
        "choice1": "保険金とは、保険事故により損害が生じたとき、保険会社が被保険者または保険金受取人に支払う金銭のことである。",
        "choice2": "超過保険とは、保険金額が保険の対象の価額（保険価額）を超えている保険のことである。",
        "choice3": "再調達価額とは、保険の対象と同等のものを新たに建築または購入するのに必要な金額から、使用による消耗分を差し引いた金額のことである。",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 305,
        "shikenId": 2,
        "parentNo": 1,
        "no": 15,
        "groupId": 6,
        "answerType": 4,
        "question1": "",
        "question2": "西住さんは、２０２４年７月に新築のアパートを購入し、新たに不動産賃貸業を開始した。購入したアパートの建物部分の情報は下記＜資料＞のとおりである。西住さんの２０２４年分の所得税における不動産所得の金額の計算上、必要経費に算入する減価償却費の金額として、正しいものはどれか。<br><img src=\"/img/question/215_1.png\" class=\"question-img\" />",
        "choice1": "８２５,０００円",
        "choice2": "１,６１２,５００円",
        "choice3": "１,６５０,０００円",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 306,
        "shikenId": 2,
        "parentNo": 1,
        "no": 16,
        "groupId": 7,
        "answerType": 4,
        "question1": "",
        "question2": "赤木さんは、自宅として所有している土地と建物を２０２４年中に譲渡する予定である。赤木さんの土地と建物の譲渡に係る所得税の計算に関する次の記述の空欄（ア）～（ウ）にあてはまる語句の組み合わせとして、正しいものはどれか。<br> <br> <div class='b-box'>・ 土地と建物などの譲渡による所得は（ ア ）所得として（ イ ）課税の対象となる。<br> ・ 土地と建物などの（ ア ）所得の金額は原則として、「譲渡価額－取得費－（ ウ ）」として計算する。</div>",
        "choice1": "（ア）譲渡　　（イ）総合　（ウ）必要経費",
        "choice2": "（ア）不動産　（イ）総合　（ウ）必要経費",
        "choice3": "（ア）譲渡　　（イ）分離　（ウ）譲渡費用",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 307,
        "shikenId": 2,
        "parentNo": 1,
        "no": 17,
        "groupId": 7,
        "answerType": 4,
        "question1": "",
        "question2": "会社員の中野望さんが２０２４年中に支払った医療費等が下記＜資料＞のとおりである場合、望さんの２０２４年分の所得税の確定申告における医療費控除の金額として、正しいものはどれか。なお、望さんの２０２４年中の所得は、給与所得８００万円のみであり、支払った医療費等はすべて望さんおよび生計を一にする妻のために支払ったものである。また、医療費控除の金額が最も大きくなるよう計算することとし、セルフメディケーション税制（特定一般用医薬品等購入費を支払った場合の医療費控除の特例）については、考慮しないものとする。<br><img src=\"/img/question/217_1.png\" class=\"question-img\" /><br> <br> （※１）人間ドックの結果、重大な疾病は発見されていない。<br> （※２）この入院について、加入中の生命保険から入院給付金が６万円支給された。",
        "choice1": "１９万円",
        "choice2": "２５万円",
        "choice3": "２７万円",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 308,
        "shikenId": 2,
        "parentNo": 1,
        "no": 18,
        "groupId": 7,
        "answerType": 4,
        "question1": "",
        "question2": "２０２４年１月５日に相続が開始された三河太一朗さん（被相続人）の＜親族関係図＞が下記のとおりである場合、民法上の相続人および法定相続分の組み合わせとして、正しいものはどれか。なお、記載のない条件については一切考慮しないこととする。<br><img src=\"/img/question/218_1.png\" class=\"question-img\" />",
        "choice1": "美佐子 ２／３　みき １／３",
        "choice2": "美佐子 １／２　みき １／２",
        "choice3": "美佐子 １／２　優佳 １／２",
        "answer": 1,
        "score": 5,
        "image": ""
    },
    {
        "id": 309,
        "shikenId": 2,
        "parentNo": 1,
        "no": 19,
        "groupId": 7,
        "answerType": 4,
        "question1": "",
        "question2": "相続開始後の各種手続きにおける下記＜資料＞の空欄（ア）、（イ）にあてはまる語句の組み合わせとして、最も適切なものはどれか。なお、記載のない事項については一切考慮しないこととする。<br><img src=\"/img/question/219_1.png\" class=\"question-img\" />",
        "choice1": "（ア）地方裁判所 （イ） ６ヵ月",
        "choice2": "（ア）地方裁判所 （イ）１０ヵ月",
        "choice3": "（ア）家庭裁判所 （イ）１０ヵ月",
        "answer": 3,
        "score": 5,
        "image": ""
    },
    {
        "id": 310,
        "shikenId": 2,
        "parentNo": 1,
        "no": 20,
        "groupId": 7,
        "answerType": 4,
        "question1": "",
        "question2": "姉歯稔さん（７８歳）は、将来発生するであろう自身の相続について、遺産分割等でのトラブルを防ぐために公正証書遺言の作成を検討しており、ＦＰの桐谷さんに相談をした。公正証書遺言に関する桐谷さんの次の説明のうち、最も適切なものはどれか。",
        "choice1": "「すでに作成した公正証書遺言を撤回したい場合、自筆証書遺言では撤回することはできません。」",
        "choice2": "「公正証書遺言を作成する場合、証人の立会いは必要ありません。」",
        "choice3": "「公正証書遺言を作成した場合、相続発生後、家庭裁判所に対してその検認を請求する必要はありません。」",
        "answer": 3,
        "score": 5,
        "image": ""
    }
]