import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/ResultView.vue')
  },
  {
    path: '/shiken/:shikenId/type/:shikenType/start',
    name: 'startQuestion',
    component: () => import('../views/StartQuestion.vue')
  },

  {
    path: '/shiken/:shikenId/q/:id',
    name: 'question',
    component: () => import('../views/Question.vue')
  },
  {
    path: '/auth/:grade',
    name: 'Auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/:grade',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'Start',
    component: () => import('../views/Start.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   if (savedPosition) {
  //      return savedPosition
  //   } else {
  //      return { x: 0, y: 0 }
  //   }
  // }
})

export default router
