
import { Component, Prop, Vue, Emit } from "vue-property-decorator";


@Component
export default class Header extends Vue {

  created() {

  }
  mounted() {}
}
