
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Component({
  components: { Header, Footer }
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();
  private isAllow: boolean = BasicUtil.isAllow();
  private grade: string = this.$route.params.grade ?? "0";
  private isAuth: boolean = false
  private isAuth33: boolean = false
  created() {
    document.body.style.background = "#dae6f2"
    this.isAuth = BasicUtil.isAuth("3");
    this.isAuth33 = BasicUtil.isAuth("33");
    if (!this.isAuth && !this.isAuth33) this.$router.push("/auth/3");
  }
  mounted() {
    new BasicUtil().removeBasicSetting();
    new StorageService().removeCurrentData();
  }

  private execTuto() {
    this.$router.push(`/shiken/1/type/0/start`);
  }

  private exec(shikenId: number) {
    this.$router.push(`/shiken/${shikenId}/type/1/start`);
  }

  private execJitsugi(shikenId: number, type: number) {
    this.$router.push(`/shiken/${shikenId}/type/${type}/start`);
  }
  private backAuth() {
    this.$router.push("/auth/3");
  }
  private backAuth2() {
    this.$router.push("/auth/3");
  }
}
