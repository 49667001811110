
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {

  private title: string = "FP"
  created() {

  }
  mounted() {}
}
