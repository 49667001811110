
import { Component, Vue } from "vue-property-decorator";

import BasicUtil from "./services/basicUtil";
@Component({
  components: {}
})
export default class App extends Vue {

  created() {
    
  }

  mounted() {
    
    // const agent = window.navigator.userAgent.toLowerCase();
    // if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
    // } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // } else if (agent.indexOf("chrome") != -1) {
    // } else if (agent.indexOf("safari") != -1) {
    //   window.onpopstate = function(event: any) {
    //     window.location.href = "/?error=back";
    //   };
    // } else if (agent.indexOf("firefox") != -1) {
    // } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    // }
  }
}
