import Question from "@/models/question";
import HttpClient from "./httpClient";

export default class StorageService {
  public setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public getData(key:string) {
    const data = localStorage.getItem(key);
    if (data == null || data == undefined) {
      this.setData(key, [])
      return []
    }
    return JSON.parse(data)
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public getCurrentData(): Question[] {
    const json = sessionStorage.getItem('currentData')
    return json != null ? JSON.parse(json) : []
  }

  public setCurrentData(data: Question[]) {
    sessionStorage.setItem('currentData', JSON.stringify(data));
  }

  public removeCurrentData() {
    sessionStorage.removeItem('currentData')
  }
}
