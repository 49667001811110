import Vue from 'vue'
import Vuex from 'vuex'
import Question from '../models/question'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    data: [] as Question[],
    rest: 0 as number,
    goal: 0 as number,
    isOpenMenu: true as boolean
  },

  mutations: {
    setQuestions(state, questions: Question[]) {
      state.data = questions
    },
    setRest(state, rest: number) {
      state.rest = rest
    },

    setGoal(state, goal: number) {
      state.goal = goal
    },

    setIsOpenMenu(state, isOpenMenu: boolean) {
      state.isOpenMenu = isOpenMenu
    },
  }

})

export default store